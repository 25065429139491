.plush {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite; }

@keyframes pulse {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        opacity: 1; }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1; }
    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        opacity: 1; } }

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 1; }
    50% {
        -webkit-transform: scale(1);
        opacity: 1; }
    100% {
        -webkit-transform: scale(0.95);
        opacity: 1; }
}

.advert{
    margin: 0;
    color: #fff;
    background: #000;
    padding: 10px 0;
    text-align: center;
}

.citations{
    ol{
        background: #fff;
        color: #0082be;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        padding-top: 2em;
        padding-bottom: 2em;
        word-wrap: break-word;
    }
}

.index-page{
    background: #11233d;
    @media(min-width:1280px){
        .container{
            padding: 0;
        }
    }
    .padding-t-l{
        padding-top: 49px;
        padding-left: 56px
    }
    .padding-all{
        padding-top: 49px;
        padding-left: 56px;
        padding-right: 56px;
        padding-bottom: 40px;
    }
    .pl-0{
        padding-left: 0;
    }
    .pr-0{
        padding-right: 0;
    }
    .top-titles{
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 800;
        font-size: 42px;
        color: #000000;
        letter-spacing: -0.25px;
        line-height: 42px;
        margin-bottom: 20px;
    }
    .my-glass-btn{
        a{
            color: #ffffff;
            text-decoration: none;
        }
        cursor: pointer;
        background-color: #0c9449;
        font-family: 'Nunito Sans', sans-serif;
        font-size:33px;
        color: #ffffff;
        font-weight: 800;
        width: 70%;
        border-radius: 60px;
        margin: 0 auto 10px auto;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5px;
        line-height: 27px;
        padding:20px 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        img{
            margin-left: 30px;
        }
    }
    .yellow-title-reality{
        text-align: center;
        font-family: 'Nunito Sans', sans-serif;
        margin: 0;
        font-size: 40px;
        color: #000000;
        font-weight: 800;
        background-color: #ffff00;
        margin: 10px auto 0 auto;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 8px;
        padding: 10px;
        line-height: 30px;
        width: 173px;
        @media screen and (min-width: 768px){
            width: 280px;
        }

        &--reality{
            width: 197px;

            @media screen and (min-width: 768px){
                width: 390px;
            }
        }
        &:after{
            content: '';
            padding-right: 15px;
            border-right: 10px solid transparent;
            border-top: 50px solid #ffff00;
            position: absolute;
            top: 0px;
            right: -20px;
        }
        &:before{
            content: '';
            padding-right: 15px;
            border-left: 10px solid transparent;
            border-bottom:50px solid #ffff00;
            position: absolute;
            top: 0px;
            left: -20px;
        }
    }
    .discription{
        p{
            font-family: 'Nunito Sans', sans-serif;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
            padding-bottom: 24px;
            margin: 0;
            &:last-child{
                // padding-bottom: 0;
            }
            strong{
                color: #000000;
            }
        }
        h3{
            font-family: 'Oswald', sans-serif;
            font-size: 36px;
            font-weight: 500;
            color: #000000;
            padding-bottom: 24px;
            margin: 0;
            letter-spacing: -0.25px;
            line-height: 35px;
        }
    }
    .head-section{
        .inside-head-section{
            position: relative;
            background-image: url(../images/header-bg.jpg);
            background-repeat: no-repeat;
            background-position: center; 
            text-align: center;
            padding: 38px 20px 30px 20px;
            .cloude-img{
                position: absolute;
                right: 0;
                bottom: -52px;
                img{
                    width: 81%;
                    margin: 0 0 0 auto;
                }
            }
        }
        .logo{
            padding-bottom: 40px;
            text-align: center;
            img{
                display: inline;
            }
        }
        .yellow-title{
                font-size: 20px;
                color: #000000;
                letter-spacing: 11px;
                background-color: #ffff00;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                text-transform: uppercase;
                width: 210px;
                position: relative;
                margin: 0 auto 20px auto;
                padding: 5px;

                @media screen and (min-width: 768px){
                    width: 395px;
                }
                &:after{
                    content: '';
                    padding-right: 5px;
                    border-right: 10px solid transparent;
                    border-top: 38px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    right: -11px;
                }
                &:before{
                    content: '';
                    padding-right: 5px;
                    border-left: 10px solid transparent;
                    border-bottom:38px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    left: -11px;
                }
        }
        .track-on{
            .normal{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 300;
                color: $light-blue;
                font-size: 46px;
                letter-spacing: -0.2px;
                line-height: 50px;
                text-transform: uppercase;
            }
            .semi-title{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                color: $white;
                font-size: 66px;
                letter-spacing: -0.25px;
                line-height: 50px;
                text-transform: uppercase;
                padding-bottom: 8px;
            }
            .title{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                color: $white;
                font-size: 152px;
                letter-spacing: -0.75px;
                line-height: 98px;
                text-transform: uppercase;
                text-align: center;
                img{
                    display: inline;
                }
            }
        }
    }
    .common-layout{
        .discription{
            padding-right: 63px;
        }
        .sleep-problem-content{
            background-image: url(../images/sleep.jpg);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: center right;
            background-color: #fbfbfb;
            padding: 40px 40px 7px 56px;
            position: relative;
            .titile{
                background-color: #2b71bb;
                font-size: 24px;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                color: $white;
                line-height: 26px;
                text-align: center;
                margin-bottom: 24px;
                position: relative;
                padding: 5px;
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top:36px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    right: -24px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom: 36px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
        }
        .discover-solution{
            background-color: $white;
            padding: 60px 75px;
            .solution-title{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: bold;
                font-style: italic;
                font-size: 17px;
                color: #000000;
                letter-spacing: 3px;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                padding-bottom: 40px;
            }
            .solution-words{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                font-size: 25px;
                margin: 0 auto;
                color: #000000;
                text-align: center;
                width: 79%;
                padding-bottom: 30px;
            }
            .solution-logos{
                text-align: center;
                padding-bottom: 33px;
                img{
                    margin-right: 20px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            .solution-list-box{
                .first{
                    .list-box-content{
                        p{
                            width: 80%;
                        }
                    }    
                }
                .list-box-content{
                    display: flex;
                    p{
                        padding-top: 20px;
                        margin: 0;
                        font-size: 20px;
                        color: #000000;
                        line-height: 26px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                        padding-left: 20px;
                        width: 90%;
                    }
                    h2{
                        padding-top: 12px;
                        margin: 0;
                        font-family: 'Yellowtail', cursive;
                        font-size: 50px;
                        color:#15959f;
                    }
                }
            }
        }
    }
    .brick-off{
        .inside-brick-off{
            background-image: url(../images/months-bg.jpg);
            background-repeat: no-repeat;
            padding: 56px 20px;
            h2{
               text-align: center;
               font-family: 'Yellowtail', cursive;
               margin: 0;
               font-size: 50px;
               color: #b5d1ff;
            }
            h3{
                text-align: center;
                font-family: 'Nunito Sans', sans-serif;
               margin: 0;
               font-size: 50px;
               color: #ffffff;
               font-weight: 800;
               text-transform: uppercase;
            }
            .disaster{
                text-align: center;
                font-family: 'Nunito Sans', sans-serif;
               margin: 0;
               font-size: 50px;
               color: #000000;
               font-weight: 800;
               background-color: #ffff00;
               width: fit-content;
               margin: 0 auto;
            }
        }
    }
    .kevin-box{
        .inside-kevi-box{
            background-image: url(../images/kevin.jpg);
            background-repeat: no-repeat;
            background-position: center;
            padding: 48px 40px 90px 56px;
            @media(min-width:1280px){
                padding: 0px 40px 90px 56px;
            }
            background-size: auto;
            h2{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 50px;
                color: #000000;
                letter-spacing: -0.25px;
            }
            h3{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: bold;
                font-size: 17px;
                color: #333333;
                letter-spacing: 2.5px;
                font-style: italic;
                line-height: 26px;
                text-transform: uppercase;
            }
        }
    }
    .my-story{
        .inside-story{
            background-image: url(../images/my-story-top-bg.png);
            background-repeat: no-repeat;
            background-position: top center;
            margin-top: -162px;
            .my-story-title{
                padding-top: 120px;
                p{
                    font-size: 16px;
                    color: #000000;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: bold;
                    line-height: 11px;
                    letter-spacing: 10px;
                    text-align: center;
                    padding-bottom: 12px;
                    text-transform: uppercase;
                }
                h2{
                    background-image: url(../images/line.png);
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    text-align: center;
                    margin: 0;
                    font-size: 120px;
                    font-family: 'Yellowtail', cursive;
                    line-height: 88px;
                    color: #000000;
                    padding-bottom: 75px;
                    text-transform: capitalize;
                    padding-bottom: 40px;
                    text-transform: capitalize;
                    margin-bottom: 35px;
                }
            }
            .fix-story{
                width: 85%;
                margin: 0 auto;
                img{
                    margin-left: 21px;
                }
                .discription{
                    padding-left: 34px;
                    padding-top: 24px;
                }
            }
        }
        .started-story{
            background-image: url(../images/story-year-ago.jpg);
            background-repeat: no-repeat;
            background-position: top center;
            padding-top: 60px;
            margin-top: -30px;
            padding-bottom: 25px;
            .started-title{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 42px;
                color: #000000;
                letter-spacing: -0.25px;
                line-height: 42px;
                margin-bottom: 20px;
            }
        }
        .have-everything{
            background-color: #4f94bf;
            padding:68px 75px;
            .inside-have-everything{
                .col-inside-have{
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .everything-list{
                    img{
                        width: 100%;
                    }
                    .discriptions{
                        background: #ffffff;
                        position: relative;
                        p{
                            padding: 55px 20px 20px 20px;
                            font-size: 17px;
                        }
                        .label{
                            position: absolute;
                            top: -25px;
                            background: #ffff00;
                            color: #000000;
                            font-family: 'Yellowtail', cursive;
                            font-size: 40px;
                            line-height: 28px;
                            margin: 0;
                            border-radius: 0;
                            padding: 14px 10px;
                            font-weight: normal;
                            &:after{
                                content: '';
                                padding-right: 15px;
                                border-right: 10px solid transparent;
                                border-top:56px solid #ffff00;
                                position: absolute;
                                top: 0px;
                                right: -24px;
                            }
                            &:before{
                                content: '';
                                padding-right: 15px;
                                border-left: 10px solid transparent;
                                border-bottom: 56px solid #ffff00;
                                position: absolute;
                                top: 0px;
                                left: -20px;
                            }
                        }
                    }
                }
            }
            .everything-btn{
                padding-top: 25px;
                text-align: center;
                img{
                    display: inline;
                }
            }
        }
        .best-me{
            background-image: url(../images/story-box4-bg.png);
            background-repeat: no-repeat;
            background-position: top center;
            padding-bottom: 35px;
            background-color: #eaeaea;
            .inside-best-me{
                .best-me-img{
                    img{
                        height: 550px;
                    }
                }
                .best-me-content{
                    padding: 44px 20px 55px 0;
                    .top-titles{
                        padding-right: 50px;
                    }
                }
            }
            .my-job-me{
                background-image: url(../images/story1-bg.png);
                background-repeat: no-repeat;
                background-position: top center;
                background-size: cover;
                margin: -32px auto 0 auto;
                // transform: rotate(1deg);
                width: 90%;
                .discription{
                    padding-top:30px;
                    padding-left: 51px;
                    padding-bottom: 40px;
                    p{
                        color: #d4e2ec;
                        padding-bottom: 16px;
                    }
                    h2{
                        margin: 0;
                        font-family: 'Oswald', sans-serif;
                        font-weight: 500;
                        color: #ffffff;
                        font-size: 36px;
                        line-height: 36px;
                        letter-spacing: -0.25px;
                        span{
                            margin: 0;
                            font-family: 'Oswald', sans-serif;
                            font-weight: 500;
                            color: #afc9da;
                            font-size: 36px;
                            line-height: 36px;
                            letter-spacing: -0.25px;
                            padding: 0;
                        }
                    }
                }
                .job-img{
                    margin-top: -50px;  
                    text-align: center;
                    img{
                        height: auto;
                        padding-right: 15px;
                        display: inline;
                    }
                }
            }
        }
        .slowly-breaking{
            background-image: url(../images/breaking-slow.jpg);
            background-repeat: no-repeat;
            background-position: top center; 
            background-size: cover; 
            // background: #242426;
            .inside-best-me{
                .padding-t-l{
                    padding-left: 70px;
                    padding-bottom: 15px;
                }
                h2{
                    color: #ffffff;
                    line-height: 42px;
                    letter-spacing: -0.25px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    font-size: 42px;
                    padding-right: 40px;
                    padding-bottom: 20px;
                    margin: 0;
                    span{
                        color: #b9b9b9;
                    }
                }
                .discription{
                    padding-right: 60px;
                    p{
                        color:#b9b9b9;
                        padding-bottom: 20px;
                        strong{
                            color: #ffffff;
                        }
                    }
                } 
                .img{
                    img{
                        height:auto;
                    }
                }
            }
        }
        .crazy-driving{
            background: #ffffff;
            .top-titles{
                padding-top: 40px;
                padding-right: 70px;
                padding-left: 25px;
            }
            .discription{
                padding-right: 50px;
                padding-left: 25px;
                p{
                    padding-bottom: 15px;
                }
            }
        }
    }
    .new-reality{
        .main-new-reality{
            background-image: url(../images/hard-time-bg.jpg);
            background-repeat: no-repeat;
            background-position: top center;   
            background-color: #253540;
            .devider{
                padding: 10px 0;
            }
            .inside-new-reality{
                padding: 46px 20px;
                h2{
                    text-align: center;
                    font-family: 'Yellowtail', cursive;
                    margin: 0;
                    font-size: 50px;
                    color: #ffffff;
                }
                h3{
                    text-align: center;
                    font-family: 'Nunito Sans', sans-serif;
                    margin: 0;
                    font-size: 50px;
                    color: #ffffff;
                    font-weight: 800;
                    text-transform: uppercase;
                }
                .new-reality-title{
                    text-align: center;
                    font-family: 'Nunito Sans', sans-serif;
                    margin: 0;
                    font-size: 50px;
                    color: #000000;
                    font-weight: 800;
                    background-color: #ffff00;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    margin: 0 auto;
                    text-transform: uppercase;
                }
            }
            .new-reality-box{
                padding-left: 48px;
                padding-top: 20px;
                padding-bottom: 30px;
                &.feeling-new-reality-box{
                    .discription{
                        padding-left: 55px;
                    }
                    .top-titles{
                        padding-left: 55px;
                    }
                }
                .img{
                    padding-right: 20px;
                    padding-left:34px;
                    img{
                        width: 97%;
                    }
                }
                .top-titles{
                    color: #ffffff;
                    padding-right: 68px;
                    padding-left: 0px;
                    font-size: 42px;
                    span{
                        color: #a8bfcc;
                    }
                }
                .title{
                    color: #a8bfcc;
                    line-height: 42px;
                    letter-spacing: -0.25px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    font-size: 42px;
                    padding-right: 20px;
                    padding-bottom: 20px;
                    margin: 0;
                    span{
                        color: #ffffff;
                    }
                }
                .discription{
                    padding-right: 40px;
                    padding-left: 0;
                    p{
                        color:#d4e2ec;
                        strong{
                            color: #ffffff;
                        }
                    }
                }
            }
            .impacts-wrose-box{
                width: 70%;
                margin: 0 auto;
                .top-titles{
                    color: #ffffff;
                    padding-right: 10px;
                    span{
                        color: #a8bfcc;
                    }
                }
                .sub-title{
                    font-size: 17px;
                    text-transform: uppercase;
                    color: #d4e2ec;
                    letter-spacing: 2px;
                    line-height: 26px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: bold;
                    font-style: italic;
                    padding-right: 10px;
                    padding-bottom: 20px;
                }
                .discription{
                    p{
                        color: #d4e2ec;
                        strong{
                            color: #ffffff;
                        }
                    }
                } 
                .img{
                    padding-top: 45%;
                    width: 114%;
                }  
            }
        }
    }
    .profestional-help{
        .inside-profestional-help{
            background-image: url(../images/profetional.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .top-titles{
                font-size: 42px;
            }
            .discription{
                padding-right: 135px;
                padding-bottom: 20px;
            }
        }
        .risk-for-hours{
            background-color: #ffffff;
            padding: 45px 10px 15px 10px;
            .risk-for-hours-box{
                width: 85%;
                margin: 0px auto;
                border: 5px solid #e7e7e7;
                padding: 50px 20px 20px 20px;
                .logo{
                    text-align: center;
                    margin-bottom: 20px;
                    img{
                        display: inline;
                    }
                }
                h3{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    font-size: 25px;
                    color: #000000;
                    text-align: center;
                    margin: 0;
                }
                .risk-list{
                    padding: 40px 10px;
                    margin: 0 auto;
                    width: 35%;
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        li{
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 20px;
                            color: #333333;
                            line-height: 33px;
                            span{
                                padding-right: 10px;
                                font-size: 30px;
                                font-family: 'Yellowtail', cursive;
                                color: #20558a;
                            }
                        }
                    }
                }
                .fucntion-last-title{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: bold;
                    font-style: italic;
                    font-size: 17px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    color: #000000;
                    margin: 0 auto;
                    width: 68%;
                    text-align: center;
                    border-top: 1px solid #a1a1a1;
                    padding-top: 20px;
                }
            }
        }
        .poor-risk{
            background-color: #ffffff;
            padding-bottom: 20px;
            padding-right:40px;
        }
    }
    .take-action{
        .take-action-title{
            background: #206896;
            padding: 50px 10px;
            h3{
                text-align: center;
                font-family: 'Yellowtail', cursive;
                margin: 0;
                font-size: 36px;
                color: #ffffff;
            }
            h2{
                text-align: center;
                font-family: 'Nunito Sans', sans-serif;
                margin: 0;
                font-size: 50px;
                color: #ffffff;
                font-weight: 800;
                text-transform: uppercase;
            }
            .yellow-title{
                text-align: center;
                font-family: 'Nunito Sans', sans-serif;
                margin: 0;
                font-size: 40px;
                color: #000000;
                font-weight: 800;
                background-color: #ffff00;
                margin: 10px auto 0 auto;
                text-transform: uppercase;
                position: relative;
                letter-spacing: 8px;
                padding: 10px;
                line-height: 30px;
                width: 196px;
                @media screen and (min-width: 768px){
                    width: 382px;
                }

                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top: 50px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    right: -20px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom:50px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
        }
        .figure-section{
            background-image: url(../images/action-bg.jpg);
            background-repeat: no-repeat;
            background-position: center;
            padding-bottom: 20px;
            background-size: cover;
            .title-our{ 
                font-family: 'Oswald', sans-serif;
                font-size: 36px;
                letter-spacing: -0.25px;
                line-height: 35px;
                padding-bottom: 20px;
                font-weight: 500;
            }
            h3{
                margin-bottom: 20px;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 17px;
                color: #333333;
                text-transform: uppercase;
                line-height: 26px;
                letter-spacing: 2px;
                font-weight: bold;
                font-style: italic;
            }
            .by-devices{
                font-size: 25px;
                color: #ffffff;
                line-height: 26px;
                letter-spacing: -0.25px;
                font-family: 'Nunito Sans', sans-serif;
                background-color: #2b71bb;
                position: relative;
                font-weight: 800;
                padding: 10px;
                width: 836px;
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top:46px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    right: -24px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom: 46px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
        }
        .level-section{
            background: #ffffff;    
            padding-left: 56px;
            padding-right: 56px;
            .awake-alert{
                .floating-img{
                    img{
                        margin: 0 0 0 auto;
                    }
                }
                .discription{
                    P{
                        padding-bottom: 22px;
                        &:last-child{
                            padding-bottom: 0;
                        }
                    }
                }
            }
            .stop-producting h3{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 25px;
                color: #ffffff;
                letter-spacing: -0.25px;
                background-color: #2b71bb;
                margin-bottom: 17px;
                position: relative;
                padding: 10px;
                width: 96%;
                margin-top: 0;
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top:74px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    right: -24px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom: 74px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
            .tricky{
                font-family: 'Oswald', sans-serif;
                font-weight: 500;
                color:#000000;
                font-size: 36px;
                margin: 0;
                letter-spacing: -0.25px;
            }
            .device-img{
                padding-top: 20px;
                img{
                    margin: 0 0 0 auto;
                    &:first-child{
                        padding-bottom: 30px;
                    }
                    &:last-child{
                        padding-top: 20px;
                    }
                }
            }
            .blue-light-box{
                background-image: url(../images/light-bg.png);
                background-repeat: no-repeat;
                background-position: center; 
                background-size: 100% 100%;
                padding: 46px 10px;   
                margin-top: 30px;
                margin-bottom:35px;
                .source-blue-light{
                    text-align: center;
                    img{
                        display: inline;
                    }
                }
                .title{
                    h2{
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: bold;
                        font-style: italic;
                        font-size: 17px;
                        color: #ffffff;
                        text-transform: uppercase;
                        letter-spacing: 2.5px;
                        text-align: center;
                        margin-bottom: 31px;
                        span{
                            text-decoration: underline;
                        }
                    }
                    h3{
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: bold;
                        font-size: 25px;
                        color: #7adbff;
                        text-align: center;
                        width: 63%;
                        margin: 0 auto;
                        padding-bottom: 40px;
                    }
                }
            }
        }
    }
    .total-sanse{
        .inside-total-sanse{
            background-color: #dfe7f2;
            padding: 40px 56px;
            .total-sanse-top{
                h2{
                    font-family: 'Oswald', sans-serif;
                    font-weight: 500;
                    font-size: 36px;
                    color: #000000;
                    line-height: 26px;
                    letter-spacing: -0.25px;
                    margin-bottom: 30px;
                }
                .total-sanse-img{
                    img{
                        margin: 0 0 0 auto;
                    }
                }
            }
            .try-your-self-img{
                text-align: center;
                background-image: url(../images/try-bg.png);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding:55px 10px 100px 10px;
                margin: 20px auto 35px auto;
                width: 80%;
                .self-title{
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 50px;
                    letter-spacing: -0.25px;
                    color:#fff;
                    background-image: url(../images/try-border-img.png);
                    background-size: contain;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    margin: 0 auto 40px auto;
                    font-weight: 800;
                    width: 210px;

                    @media screen and (min-width: 768px){
                        width: 420px;
                    }
                }
                .self-title-list{
                    ul{
                        list-style: none;
                        padding: 0 0 0 20px;
                        margin: 0 auto;
                        width: 50%;
                        li{
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 20px;
                            color: #ffffff;
                            line-height: 33px;
                            text-align:left;
                            padding-bottom: 7px;
                            font-weight: 400;
                            span{
                                padding-right: 10px;
                                font-size: 30px;
                                font-family: 'Yellowtail', cursive;
                                color: #ffff00;
                            }
                        }
                    }
                }
                img{
                    display: inline;
                }
            }
            .total-sanse-last{
                h2{
                    text-align: center;
                    font-family: 'Oswald', sans-serif;
                    font-size: 36px;
                    color: #000000;
                    font-weight: 500;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    span{
                        background-image: url(../images/blue-line.png);
                        background-repeat: no-repeat;
                        background-position: bottom left;
                        background-size: 100% 18%;
                    }
                }
                .discription{
                    p{
                        text-align: center;
                        padding-bottom: 0px;
                    }
                }
            }
            .digital-boxes{
                padding-top: 30px;
                padding-bottom: 30px;
                .row{
                    width: 91%;
                    margin: 0 auto;
                }
                .solution-box{
                    position: relative;
                    .yellow-title{
                        position: absolute;
                        bottom: -20px;
                        background: #ffff00;
                        color: #000000;
                        font-family: 'Yellowtail', cursive;
                        font-size: 40px;
                        line-height: 28px;
                        margin: 0;
                        border-radius: 0;
                        padding: 14px 10px;
                        &:after{
                            content: '';
                            padding-right: 15px;
                            border-right: 10px solid transparent;
                            border-top:56px solid #ffff00;
                            position: absolute;
                            top: 0px;
                            right: -24px;
                        }
                        &:before{
                            content: '';
                            padding-right: 15px;
                            border-left: 10px solid transparent;
                            border-bottom: 56px solid #ffff00;
                            position: absolute;
                            top: 0px;
                            left: -20px;
                        }
                    }
                }
            }
        }
    }
    .better-way{
        .claim-better-way{
            background-image: url(../images/better-way.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            h3{
                font-family: 'Oswald', sans-serif;
                font-weight: 500;
                font-size: 36px;
                color: #000000;
                line-height: 36px;
                letter-spacing: -0.25px;
                width: 49%;
            }
        }
        .claim-your-glasees{
            position: relative;
            background-color: #e7ebf1;
            .glass-img{
                .arrow-img{
                    position: absolute;
                    top: -30px;
                    left: 17%;   
                    img{
                        width: 81%;
                    }         
                }
            }
            .my-glass-btn{
                a{
                    color: #ffffff;
                    text-decoration: none;
                }
                background-color: #0c9449;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 33px;
                color: #ffffff;
                font-weight: 800;
                width: 70%;
                border-radius: 60px;
                margin: 0 auto 40px auto;
                text-transform: uppercase;
                text-align: center;
                letter-spacing: 0.5px;
                line-height: 27px;
                padding: 20px 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    padding-left: 30px;
                }
            }
            .discription{
                padding-left: 40px;
                padding-right: 40px;
                width: 80%;
                margin: 0 auto;
                P{
                    text-align: center;
                    strong{
                        color: #000000;
                    }
                }
            }
            .first-thought{
                width: 85%;
                margin: 0 auto;
                background-image: url(../images/whit-bg.png);
                background-repeat: no-repeat;
                background-position: center;
                // background-size: cover;
                padding: 55px 10px;
                h3{
                    text-align: center;
                    font-style: italic;
                    font-size: 17px;
                    text-transform: uppercase;
                    color: #333333;
                    letter-spacing: 2px;
                    line-height: 26px;
                }
                h2{
                    text-align: center;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    font-size: 25px;
                    color: #000000;
                    line-height: 42px;
                    letter-spacing: -0.25px;
                }
                ul{
                    list-style: none;
                    padding: 20px 0 0 0;
                    width: 60%;
                    margin: 0 auto;
                    li{
                        background-image: url(../images/right-icon.png);
                        background-repeat: no-repeat;
                        background-position: left;
                        background-size: auto;
                        padding-left: 60px;
                        margin-bottom: 20px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                        font-size: 20px;
                        color: #000000;
                        line-height: 26px;
                        img{
                            display: inline;
                        }
                    }
                }
            }
            .glass-last-coments{
                padding-top: 30px;
                padding-bottom: 50px;
                text-align: center;
                p{
                    font-size: 20px;
                    color: #000000;
                    line-height: 26px;
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    padding: 0;
                    margin: 0;
                    &:first-child{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .blue-light-phenomena{
        .inside-blue-light-phenomena{
            background: #ffffff;
            .science-behind{
                padding-right: 5px;
            }
            .discription{
                P{
                    &:last-child{
                        padding-bottom: 0;
                    }
                }
            }
            .floating-img{
                img{
                    margin: 0 0 0 auto;
                }
            }
        }  
        .try-myself{
            background-color: #e1e1e3;
            .blue-title{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                color: #ffffff;
                font-size: 25px;
                line-height: 26px;
                background-color: #2b71bb;
                text-align: center;
                margin-left: 75px;
                margin-top: 40px;
                margin-bottom: 20px;
                padding: 10px;
                position: relative;
                width: 289px;
                @media screen and (min-width: 768px){
                    width: 439px;
                }
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top: 46px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom: 46px solid #2b71bb;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
            .discription{
                padding-left:60px;
                padding-bottom: 0px;
                p{
                    padding-bottom: 12px;
                }
            }
            .floating-img{
                margin-top: -10px;
                img{
                    height: auto;
                }
            }
        }  
    }
    .dream-elements{
        .insdie-dream-element{
            background: #ffffff;
            .top-titles{
                text-align: center;
            }
            .floating-img{
                text-align: center;
                img{
                    display: inline;
                }
            }
            .dream-elements-box{
                background-color: #15959f;
                width: 80%;
                margin: 60px auto 0 auto;
                ul{
                    list-style: none;
                    padding: 50px;
                    li{
                        background-image: url(../images/whit-right-icon.png);
                        background-repeat: no-repeat;
                        background-position: left;
                        padding-left: 60px;
                        margin-bottom: 25px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                        font-size: 20px;
                        color: #ffffff;
                        min-height: 46px;
                        display: flex;
                        line-height: 26px;
                        align-items: center;
                        &:last-child{
                            margin: 0;
                        }
                        img{
                            display: inline;
                        }
                    }
                }
            }
            .claim-btn-discription{
                padding-top: 40px;
                padding-bottom: 40px;
                .discription{
                    width: 80%;
                    margin: 0 auto;
                    p{
                        font-weight: bold;
                        text-align: center;
                    }
                }
                .btn{
                    a{
                        color: #ffffff;
                        text-decoration: none;
                    }
                    background-color: #0c9449;
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 33px;
                    color: #ffffff;
                    font-weight: 800;
                    width: 70%;
                    border-radius: 60px;
                    margin: 0 auto 0px auto;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 0.5px;
                    line-height: 27px;
                    padding: 20px 12px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    img{
                        padding-left: 30px;
                    }
                }
            }
            .clinical-study{
                background-color: #e7ebf1;
                padding-bottom: 0;
                margin-bottom: 40px;
                .clinical-titles{
                    font-size: 25px;
                    color: #000000;
                    line-height: 26px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    margin-bottom: 30px;
                    text-align: center;
                }
                .discription{
                    width: 80%;
                    margin: 0 auto;
                    p{
                        text-align: center;
                        font-style: italic;
                        font-size: 16px;
                        color: #333333;
                        line-height: normal;
                        padding-bottom: 40px;
                    }
                }
            }
            .sleeping-btr{
                width: 61%;
                margin: 0 auto;
                text-align: center;
            }
            .sleep-patter{
                background-color: #f4f6f9;
                border:5px solid #e7ebf1;
                padding: 50px;
                .top-section{
                    p{
                        margin: 0;
                        text-transform: uppercase;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: bold;
                        font-style: italic;
                        font-size: 17px;
                        color: #000000;
                        letter-spacing: 2.5px;
                        text-align: center;
                        padding-bottom: 25px;
                    }
                    h2{
                        margin: 0;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 800;
                        font-size: 25px;
                        color: #000000;
                        line-height: 26px;
                        letter-spacing: -0.25px;
                        text-align: center;
                        padding-bottom: 50px;
                    }
                }
                .sleep-patter-box{
                    .inside-sleep-patter{
                        text-align: center;
                        img{
                            display: inline;
                            padding-bottom: 25px;
                        }
                        p{
                            margin: 0;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight:400;
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }
            }
            .company-list{
                padding: 50px 0 0 0;
                .company-list-discrition{
                    .blue-title{
                        position: relative;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 800;
                        color: #ffffff;
                        font-size: 25px;
                        line-height: 26px;
                        background-color: #2b71bb;
                        text-align: center;
                        margin-bottom: 20px;
                        padding: 10px;
                        margin-left: 15px;
                        width: 206px;
                        @media screen and (min-width: 768px){
                            width: 307px;
                        }
                        &:after{
                            content: '';
                            padding-right: 15px;
                            border-right: 10px solid transparent;
                            border-top: 46px solid #2b71bb;
                            position: absolute;
                            top: 0px;
                        }
                        &:before{
                            content: '';
                            padding-right: 15px;
                            border-left: 10px solid transparent;
                            border-bottom: 46px solid #2b71bb;
                            position: absolute;
                            top: 0px;
                            left: -20px;
                        }
                    }
                }
                .company-glass{
                    padding-top: 5px;
                    padding-bottom: 40px;
                    h2{
                        font-family: 'Oswald', sans-serif;
                        font-size: 36px;
                        color: #000000;
                        line-height: 26px;
                        letter-spacing: -0.25px;
                        text-align: center;
                        span{
                            background-image: url(../images/blue-line.png);
                            background-repeat: no-repeat;
                            background-position: bottom left;
                            background-size: 100% 14%;
                            padding-bottom: 7px;
                        }
                    }
                }
                .glass-btn{
                    a{
                        color: #ffffff;
                        text-decoration: none;
                    }
                    background-color: #0c9449;
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 33px;
                    color: #ffffff;
                    font-weight: 800;
                    width: 70%;
                    border-radius: 60px;
                    margin: 0 auto 0px auto;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 0.5px;
                    line-height: 27px;
                    padding:20px 12px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    img{
                        padding-left: 30px;
                    }
                }
            }
        }
    }
    .real-people{
        .inside-real-people{
            background-color: #2f4c75;
            .real-people-box{
                padding: 0 8px;
                .row div.col-lg-4{
                    padding: 0px 8px;
                }   
            }
            .top-titles{
                color: #ffffff;
                text-align: center;
            }
            .discription{
                text-align: center;
                p{
                    color: #ffffff;
                }
            }
            .blank-box{
                img{
                    width: 100%;
                }
            }
        }
        .anti-blue-light-content{
            background-color: #ffffff;
            .discription{
                p{
                    padding-bottom: 15px;
                }
            }
        }
        .want-that-life{
            background-color: #238e88;
            .row{
                padding-bottom: 40px;
            }
            .imagine-life{
                text-align: center;
                img{
                    display: inline;
                }
            }
            .top-titles{
                text-align: center;
                color: #ffffff;
                padding-bottom: 30px;
            }
            .inside-want-that-life{
                padding-left: 30px;
                display: flex;
                .life-img{
                    padding-bottom: 2px;
                    text-align: center;
                    img{
                        display: inline;
                    }
                }  
                .life-tag{
                    padding: 30px;
                    margin: "0";
                    background-image: url(../images/life-tag-bg.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    font-size: 40px;
                    font-family: 'Yellowtail', cursive;
                    color: #000000;
                    line-height: 20px;
                }
                .discription{
                    padding-left: 30px;
                    width: 55%;
                    p{
                        color: #ffffff;
                    }
                }
            }
        }
        .give-anythings{
            background-color: #ffffff;
            .inside-anything{
                .inside-give-anything{
                    .top-titles{
                        width: 80%;
                    }
                }
            }
            .looking-for-solution{
                background-image: url(../images/solution-bg.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                padding: 40px 5px;
                margin-top: 15px;
                .top-titles{
                    text-align: center;
                    width: 80%;
                    margin: 0 auto;
                    padding-bottom: 30px;
                }
                .discription{
                    width: 60%;
                    margin: 0 auto;
                    padding-bottom: 20px;
                    p{
                        text-align: center;
                        &.upppercase{
                            text-transform: uppercase;
                            font-style: italic;
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 17px;
                            letter-spacing: 2px;
                            font-weight: bold;
                        }
                    }
                }
                .row{
                    width: 92%;
                    margin: 0 auto;
                }
                .solution-lists{
                    text-align: center;
                    img{
                        display: inline;
                        padding-bottom: 25px;
                    }
                    p{
                        font-family: 'Nunito Sans', sans-serif;
                        font-size: 20px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 26px;
                        padding-bottom: 24px;
                        margin: 0;
                    }
                }
            }
        }
        .bonuses{
            background-color: #2f4c75;
            .insdie-bonuses{
                padding-top: 40px;
                .yelloe-bonus{
                    font-size: 45px;
                    color: #000000;
                    letter-spacing:0;
                    background-color: #ffff00;
                    font-family: 'Yellowtail', cursive;
                    // font-weight: 800;
                    text-transform: capitalize;
                    position: relative;
                    margin: 0 auto 20px auto;
                    padding: 3px 5px 6px 5px;
                    line-height: 40px;
                    width: 144px;

                    @media screen and (min-width: 768px){
                        width: 233px;
                    }

                    @media screen and (min-width: 960px){
                        width: 149px;
                    }
                    &:after{
                        content: '';
                        padding-right: 8px;
                        border-right: 15px solid transparent;
                        border-top: 49px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        right: -20px;
                    }
                    &:before{
                        content: '';
                        padding-right: 8px;
                        border-left: 15px solid transparent;
                        border-bottom: 49px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        left: -20px;
                    }
                }
                h2{
                    text-align: center;
                    margin: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 300;
                    color: #b5d1ff;
                    font-size: 42px;
                }
                h3{
                    text-align: center;
                    margin: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    color: #ffffff;
                    line-height: 50px;
                    letter-spacing: -0.25px;
                    font-size: 42px;
                    padding-bottom: 30px;
                }
                h2.sleep-cls{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    color: #fefefe;
                    font-size: 25px;
                    text-align: left;
                    padding-bottom: 20px;
                }
                .discription{
                    padding-top: 50px;
                    padding-bottom: 40px;
                    p{
                        color: #becbde;
                    }
                }
                ul{
                    list-style: none;
                    padding-left: 20px;
                    margin: 0;
                    width:80%;
                    li{
                        font-family: 'Nunito Sans', sans-serif;
                        font-size: 20px;
                        color: #bfccde;
                        line-height: 26px;
                        background-image: url(../images/white-right.png);
                        background-repeat: no-repeat;
                        background-position: left top 4px;
                        padding-left: 30px;
                        padding-bottom: 15px;
                    }
                }
                .row{
                    width: 80%;
                    margin: 0 auto;
                    .floating-img{
                        text-align: right;
                        img{
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    .thousands{
        &.slepping-btr-content{
            .need-to-feel{
                position: relative;
                padding: 0 0 40px 0;
                background-size: cover;
                background-image: url(../images/better-bg-img.jpg);
                .cloude-img-better{
                    position: absolute;
                    left: 0;
                    bottom:100px;
                }
                .arrow-custom{
                    padding-bottom: 40px;
                }
                .get-title{
                    font-size: 112px;
                    line-height: 62px;
                    padding-right: 14px;
                    transform: rotate(-4deg);
                }
                .glass-img{
                    text-align: center;
                    margin-top: -23px;
                    img{
                        z-index: 99999;
                        position: relative;
                    }
                }
                .my-glass-btn{
                    margin-top: -20px;
                }
            }
        }
        &.anti-blue-light-dreamelements{
           .need-to-feel{
               h2{
                    font-size: 60px; 
                    padding-bottom: 8px; 
               }
                h4{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    color: #fff;
                    font-size: 75px;
                    letter-spacing: -0.25px;
                    line-height: 50px;
                    text-transform: uppercase;
                    padding-bottom: 14px;
                    text-align: center;
                }
                h3{
                    font-size: 200px;
                    letter-spacing: -0.25px;
                    line-height: 129px;
                }
                .yellow-title{
                    font-size: 25px;
                    color: #000000;
                    background-color: #ffff00;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: bold;
                    position: relative;
                    margin: 5px auto 25px auto;
                    padding: 5px;

                    @media screen and (min-width: 768px){
                        width: 620px;
                    }

                    &:before{
                        content: '';
                        padding-right: 15px;
                        border-left: 10px solid transparent;
                        border-bottom: 45px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        left: -20px;
                    }
                    &:after{
                        content: '';
                        padding-right: 15px;
                        border-right: 10px solid transparent;
                        border-top: 45px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        right: -20px;
                    }
                }
           } 
        }
        .inside-thousand{
            background-image: url(../images/wake-up-bg.jpg);
            background-repeat: no-repeat;
            background-position: center;
            padding: 50px 10px;
            position: relative;
            .cloude-img{
                position: absolute;
                right: 0;
                top:5px;
            }
            .yellow-title{
                font-size: 25px;
                color: #000000;
                background-color: #ffff00;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: bold;
                position: relative;
                margin: 0 auto 25px auto;
                padding: 5px;
                &--thousands{
                    width: 143px;
                    @media screen and (min-width: 768px){
                        width: 234px;
                    }
                }
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top: 45px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    right: -20px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom:45px solid #ffff00;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
            h2{
                font-family: 'Yellowtail', cursive;
                font-size: 58px;
                color: #b5d1ff;
                line-height: 32px;
                margin: 0;
                text-align: center;
                padding-bottom: 20px;
            }   
            h3{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 75px;
                color: #ffffff;
                line-height: 50px;
                letter-spacing: -0.25px;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
            }        
            h4{
                margin: 0;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 172px;
                line-height: 116px;
                letter-spacing: -0.25px;
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
                padding-top: 22px;
            }
            .my-glass-btn{
                margin-top: 18px;
            }
            .spect{
                text-align: center;
                margin-top: -25px;
                img{
                    display: inline;
                }
            }
        }
        .sixty-day-gauranty{
            background-color: #0c9449;
            padding: 20px;
            .inside-day{
                border:1px solid #86caa4;
                padding: 40px 10px;
            }
            .top-titles{
                text-align: center;
                color: #ffffff;
                padding-bottom: 20px;
            }
            .sixty-title{
                font-family: 'Yellowtail', cursive;
                font-size: 100px;
                color: #ffffff;
                line-height: 35px;
                letter-spacing: -0.25px;
                text-align: center;
                padding-bottom: 12px;
            }
            .sixty-logo{
                text-align: center;
                padding-bottom: 20px;
                padding-top: 55px;
                img{
                    display: inline;
                }
            }
            .discription{
                width: 78%;
                margin: 0 auto;
                p{
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
        .nothing-to-loss{
            background-color: #007b37;
            .top-titles{
                text-align: center;
                color: #ffffff;
            }
            .discription{
                p{
                    color: #ffffff;
                    text-align: center;
                    &.upppercase-text{
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: bold;
                        font-style: italic;
                        text-transform: uppercase;
                        font-size: 17px;
                        color: #ffffff;
                        line-height: 27px;
                        letter-spacing: 2.5px; 
                        padding-bottom: 0;
                    }
                }
            }
            .white-title{
                font-size: 25px;
                color: #007b37;
                line-height: 26px;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                background-color: #ffffff;
                text-align: center;
                padding: 10px;
                position: relative;
                margin: 0 auto;
                width: 217px;
                @media screen and (min-width: 768px){
                    width: 320px;
                }
                &:after{
                    content: '';
                    padding-right: 15px;
                    border-right: 10px solid transparent;
                    border-top: 46px solid #ffffff;
                    position: absolute;
                    top: 0px;
                    right: -20px;
                }
                &:before{
                    content: '';
                    padding-right: 15px;
                    border-left: 10px solid transparent;
                    border-bottom:46px solid #ffffff;
                    position: absolute;
                    top: 0px;
                    left: -20px;
                }
            }
        }
        .you-need{
            background-image: url(../images/you-need-bg.jpg);
            background-repeat: no-repeat;
            background-position: center;
            padding: 70px 10px 50px 10px;
            position: relative;
            .cloude-img{
                position: absolute;
                top:0;
                right: 0;
            }
            h2{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 173px;
                color: #ffffff;
                line-height: 116px;
                letter-spacing: -0.25px;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
            }   
            h3{
                margin: 0;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size: 75px;
                line-height: 50px;
                letter-spacing: -0.25px;
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
                padding-bottom: 20px;
            } 
            .glass-img{
                text-align: center;
                img{
                    display: inline;
                }
            }   
            .tonight{
                font-family: 'Yellowtail', cursive;
                font-size: 118px;
                color: #b5d1ff;
                line-height: 66px;
                margin: 0;
                text-align: center;
                padding-bottom: 20px;
                margin-top: -6px;
                transform: rotate(-5deg);
                padding-right: 70px;
            }
            .my-glass-btn{
                margin-top: 20px;
            }
        }
        .faq{
            background: #ffffff;
            padding: 40px;
            .top-titles{
                text-align: center;
                padding-bottom: 10px;
            }
            #accordion{
                width: 90%;
                margin: 0 auto;
                  .panel-heading [data-toggle="collapse"].collapsed:after {
                    content: url(../images/faq-arrow.png);
                  }
                  .panel-heading [data-toggle="collapse"]:after {
                    content: url(../images/faq-down-arrow.png);
                    float: right;
                    object-fit: contain;
                    position: relative;
                    top: -29px;
                  }
                  .panel-heading{
                    .accordion-toggle{
                        &.collapsed .panel-title{
                            color: #000000;	
                            font-size: 20px;
                        }
                    }
                  }
            }
             .panel-title {
                font-size: 20px;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 700;
                color: #000000;
                line-height: 26px;
                .index{
                    font-size: 40px;
                    color: #238e88;
                    line-height: 28px;
                    font-family: 'Yellowtail', cursive;
                    padding-right: 15px;
                    padding-left: 15px;
                }
              }
               a {
                text-decoration: none;
                font-size: 18px;
                color: #3d3d3d;
                line-height: 30px;
                letter-spacing: -0.2px;
              }
               .panel-body {
                font-size: 20px;
                color: #333333;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                line-height: 26px;
                width: 90%;
                margin: 0 auto;
              }
               .panel-body .inside-faq {
                padding-top: 10px;
              }
               .panel-body .inside-faq p {
                margin: 0;
              }
               .panel-group .panel {
                margin-top: 16px;
                box-shadow: unset;
                border-radius: 0;
                border: none;
              }
               .panel-group .panel .panel-heading {
                background: #f0f0f0;
                border: 1px solid #bcbcbc;
                padding: 15px 15px;
                border-radius: 0;
              }
        }
        .need-to-feel{
            background-image: url(../images/refreshed-bg.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 50px 10px;
            position: relative;
            &.feel-refreshed-section{
                background-image: url(../images/feel-refreshed-bg.jpg);
                .cloude-img-feel{
                    position: absolute;
                    top: 169px;
                    right: 0;
                }
            }
            .cloude-img{
                position: absolute;
                top: 96px;
                left: 0;
            }
            .get-title{
                font-family: 'Yellowtail', cursive;
                font-size: 58px;
                color: #b5d1ff;
                line-height: 66px;
                margin: 0;
                text-align: center;
                padding-bottom: 14px;
            }
            h2{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 300;
                font-size: 75px;
                color: #b5d1ff;
                letter-spacing: -0.25px;
                line-height: 50px;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
                padding-bottom: 22px;
            }   
            h3{
                margin: 0;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size:145px;
                line-height: 97px;
                letter-spacing: -0.25px;
                color: #ffffff;
                text-align: center;
                text-transform: uppercase;
            } 
            .and{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                font-size:46px;
                letter-spacing: -0.25px;
                line-height: 18px;
                color: #ffffff;
                text-align: center;
                padding: 17px 0;
                margin: 0;
            }
            .my-glass-btn{
                margin-top: 15px;
            }
            .glass-img{
                text-align: center;
                margin-top: -16px;
                img{
                    display: inline;
                }
            }
        }
    }

    .fooot{
        padding: 30px 10px;
        .menus{
            padding: 0;
            margin: 0;
            list-style: none;
            display:flex;
            align-items: center;
            justify-content: center;
            li{
                
                a{
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 16px;
                    color: #92a9cb;
                    line-height: 30px;
                    &:after{
                        content: '|';
                        color:#92a9cb;
                        font-size: 20px; 
                        padding-left: 20px;
                        padding-right: 20px;
                    }   
                }
                &:last-child{
                    a{
                        &:after{
                            content: '';
                            padding: 0;
                        }
                    }
                }
            }
        }
        .copy-right{
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            color: #92a9cb;
            line-height: 30px;
            text-align: center;
        }
    }
}


// Responsive View

@media screen and (width:1024px) {
    .index-page{
        .take-action{
            .level-section{
                .stop-producting{
                    h3:before{
                        border-bottom: 101px solid #2b71bb;
                    }
                    h3:after{
                        border-top: 101px solid #2b71bb;
                    }
                } 
            } 
        }
        .better-way{
            .claim-better-way{
                h3{
                    width: 75%;
                }
            } 
        }  
        .index-page .blue-light-phenomena .try-myself .blue-title:before{
            left: -20px;
            border-bottom: 72px solid #2b71bb;
        }
        .index-page .blue-light-phenomena .try-myself .blue-title:after{
            right: -22px;
            border-bottom: 72px solid #2b71bb;
        }
    } 
    .total-sanse{
        .inside-total-sanse{
            .try-your-self-img{
                width: 100% !important;
            }
        }
    }
}

@media(min-width:768px) and (max-width:1279px){
    .total-sanse{
        .inside-total-sanse{
            .try-your-self-img{
                width: 100% !important;
                background-size: cover !important;
                .self-title-list ul{
                    width: 80% !important;
                }
            }
        }
    }
    .index-page .head-section .inside-head-section .cloude-img img{
        width: 200px;   
    }
    .index-page .common-layout .sleep-problem-content .titile{
        // font-size: 20px;
        // &:after{
        //     border: none !important;
        // }
        // &::before{
        //     border: none !important;
        // }
    }
    .light-img{
        img{
            width: 100%;
        }
    }
    .index-page .thousands .need-to-feel .cloude-img{
        display: none;
    }
    .index-page .thousands.slepping-btr-content .need-to-feel .cloude-img-better{
        img{
            width:50%;
        }
    }
    .index-page .thousands .inside-thousand .cloude-img img{
        width: 50%;
        margin: 0 0 0 auto;
    }
    .index-page .thousands .you-need .cloude-img img{
        width: 50%;
        margin: 0 0 0 auto;
    }
    .index-page .thousands .need-to-feel.feel-refreshed-section .cloude-img-feel img{
        width: 50%;
        margin: 0 0 0 auto;
    }
}

@media screen and (min-width: 768px) and (max-width:992px) {
    .index-page{
        // .tab-p-l-0{
        //     padding-left: 0;
        // }
        // .take-action{
        //     .level-section{
        //         .stop-producting{
        //             h3:before{
        //                 border-bottom: 128px solid #2b71bb;
        //             }
        //             h3:after{
        //                 border-top: 128px solid #2b71bb;
        //             }
        //         } 
        //     } 
        // }
        .my-story{
            .best-me{
                background-image: url(../images/story-box4-tab.jpg);
                padding-bottom: 0;
                .tab-inside-best-me{
                    .best-me-content{
                        padding-left: 20px;
                        padding-right: 0;
                        padding-bottom: 10px;
                        .top-titles{
                            padding-right: 0;
                        }
                    }
                }
                .my-job-me{
                    width:100%;
                    margin-top: -20px;
                    // background-color: #242426;
                    position: relative;
                    z-index: 99999;
                    .tab-my-job-me{
                        background-image: url(../images/story1.jpg);
                        background-repeat: no-repeat;
                        background-size: 35%;
                        background-position: right 19px bottom 14px;
                        .col-sm-12{
                            padding:0;
                        }
                        .discription{
                            padding-top: 20px;
                            padding-left: 20px;
                            padding-right: 34px;
                            padding-bottom: 40px;
                            p.tab-discri{
                                padding-right: 37%;
                            }
                        }
                    }
                }
            }
            .have-everything{
                padding: 50px 0 35px 0 !important;
                .everything-btn{
                    padding-top: 15px;
                }
            } 
            .tab-have-everything{
                .col-inside-have{
                    &:nth-child(1){
                        .discriptions{
                            .label{
                                left: -148px;      
                            }
                        }
                    }
                    &:nth-child(2){
                        .discriptions{
                            .label{
                                left: -208px;      
                            }
                        }
                    }
                    &:nth-child(3){
                        .everything-list{
                            margin-bottom: 0;
                        }
                        .discriptions{
                            .label{
                                left: -147px;      
                            }
                        }
                        .everything-list img{
                            width:288px;
                        }
                    }
                }
                &.inside-have-everything{
                    .everything-list{
                        display: flex;
                        margin-bottom: 30px;
                        background: #fff;
                        img{
                            width:310px;
                        }
                        .discriptions{
                            .label{
                                top: auto;
                                bottom:10px;       
                            }
                            .discription{
                                height: 100%;
                                p{
                                    padding: 20px 30px 20px 20px;
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                    font-size:20px;
                                    br{
                                        line-height: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .started-story{
                .started-title{
                    margin-top: 8px;
                }
                .discription{
                    p.tab-discription{
                        padding-right: 46%;
                    }
                }
            } 
            .inside-story {
                margin-top: -105px;
                .fix-story{
                    width: 100%;
                    margin: 0 auto;
                    .discription{
                        padding-left:15px;
                        padding-top: 5px;
                        padding-right: 12px;
                        padding-bottom: 30px;
                    }
                    img{
                        margin-left: 10px;
                    }
                }
            }
            .slowly-breaking{
                background-image: none;
                background-color: #242426;
                margin-top: -20px;
                .tab-inside-best-me{
                    background-color: #242426;
                }
                .inside-best-me h2{
                    padding: 30px 20px 20px 20px;
                }
            }
            .crazy-driving{
                background-image: url(../images/story2-tab.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                .discription{
                    padding-left: 0;
                }
            }
        }
        .new-reality{
            .main-new-reality{
                .new-reality-box{
                    padding-bottom: 0;
                    &.feeling-new-reality-box{
                        .discription{
                            padding-left: 20px;
                            padding-bottom: 15px;
                            padding-right: 8px;
                            p{
                                padding-bottom: 19px;
                            }
                        }
                        .tab-top-titles{
                            padding-left: 0px;
                        }
                        .row{
                            .col-sm-5{
                                padding-left: 0;
                            }
                        }
                    }
                    .tab-p-l-0{
                        padding-left: 0;
                    }
                }
                .impacts-wrose-box{
                    width: 100% !important;
                    padding-left: 10px;
                    .tab-top-titles{
                        padding-left: 20px;
                    }
                }
            }
        }
        .profestional-help{
            .row{
                .col-sm-9{
                    padding-left: 0;
                }
            }
            .inside-profestional-help{
                .discription{
                    padding-right: 138px;
                }
            }   
        } 
        .poor-risk{
            .tab-p-l-0{
                padding-left: 0;
            }
        }
        .take-action{
            .figure-section{
                background-size: cover;
                background-image: url(../images/action-bg-tab.jpg);
                padding:20px;
                .by-devices{
                    margin:0 auto;
                    font-size: 20px;
                    width:92%;
                    text-align: center;
                }
            }
            .tab-figure-section{
                background-image: none;
                background-color:#ffffff;
            }
            .level-section{
                .stop-producting h3{
                    margin: 0 auto 17px auto;
                    text-align: center;
                    width:90%;
                }  
            } 
        } 
    }
    .index-page .head-section .track-on .semi-title{
        font-size: 59px;
    }
    // .index-page .common-layout .sleep-problem-content .titile:before{
    //     border-bottom: 72px solid #2b71bb;
    // }
    // .index-page .common-layout .sleep-problem-content .titile:after{
    //     border-top: 72px solid #2b71bb;
    // }
    .index-page .kevin-box .inside-kevi-box{
        background-size:cover;
        background-image: url(../images/kevin-tab.jpg);
        padding: 20px;
        .col-sm-7{
            padding-left: 0;
        }
    }
    .index-page .my-story .started-story{
        background-size: cover;
        background-image: url(../images/story-year-ago-tab.jpg);
    }
    .index-page .my-story .have-everything .everything-btn img{
        width:85%;
    }
    .index-page .profestional-help .inside-profestional-help{
        background-size: cover;
        background-image: url(../images/profetional-tab.jpg);
    }
    .index-page .my-story .have-everything{
        padding: 50px 0;
    }
    .index-page .take-action .figure-section .by-devices{
        // text-align: center;
        // width: 90%;
        // &:before{
        //     border-bottom: 72px solid #2b71bb;
        // }
        // &::after{
        //     border-top: 72px solid #2b71bb;
        // }
    }
    .index-page .better-way .claim-better-way h3{
        width: 100%;
    }
    .index-page .blue-light-phenomena .try-myself .blue-title:before{
        // border-bottom: 72px solid #2b71bb;
    }
    .index-page .blue-light-phenomena .try-myself .blue-title:after{
        // border-top: 72px solid #2b71bb;
        // right: -20px;
    }
    .claim-btn-discription .btn{
        width: 65% !important;
    }
    .index-page .dream-elements .insdie-dream-element .sleep-patter{
        padding: 50px 0;
        width: 95%;
        margin: 0 auto;
        .sleep-patter-box .inside-sleep-patter p{
            padding-right: 0 !important;
        }
    }
    .index-page .dream-elements .insdie-dream-element .company-list .company-list-discrition .blue-title:before{
        border-bottom: 46px solid #2b71bb;
    }
    .index-page .dream-elements .insdie-dream-element .company-list .company-list-discrition .blue-title:after{
        border-top: 46px solid #2b71bb;
        right: -20px;
    }
    .index-page .dream-elements .insdie-dream-element .company-list .company-glass h2{
        line-height: 50px;
    }
    .index-page .dream-elements .insdie-dream-element .company-list .glass-btn{
        width: 75%;
    }
    .index-page .real-people .bonuses .insdie-bonuses ul{
        width: 100%;
    }
    .index-page .thousands .you-need h2{
        font-size: 102px;
    }
    .index-page .my-glass-btn{
        width: 75%;
    }
    .index-page .thousands .faq #accordion .panel-heading .accordion-toggle.collapsed .panel-title{
        padding-right: 40px;
    }
    .index-page .thousands .need-to-feel h3{
        font-size: 104px;
    }
    .index-page .thousands .need-to-feel h2{
        font-size: 58px;
    }
    .index-page .thousands .inside-thousand h4{
        font-size: 115px;
    }
    .thousands{
        &.anti-blue-light-dreamelements{
            .need-to-feel{
                //  h3{
                //     font-size: 150px !important;
                //  }
            } 
         }
    }
    .my-story{
        .best-me{
            background-color: #e5e5e5;
            background-image: none;
        }
    }
}

@media (max-width:767px){
    .index-page{
        .row{
            margin: 0;
        }
        .discription{
            p{
                padding-bottom: 15px;
                line-height: 25px;
                font-size: 15px;
            }
        } 
        .padding-all{
            padding: 25px 15px;
        }
        .padding-t-l{
            padding: 0;
        }
        .top-titles{
            font-size: 24px;
            line-height: 27px;
            margin-bottom: 15px;
        }
        .mobile-padding-none{
            padding: 0;
        }
        .pl-0{
            padding-left: 15px;
        }
        .pr-0{
            padding-right: 15px;
        }
        .yellow-title-reality{
            font-size: 20px;
            padding: 5px;
            &:before{
                border-bottom: 40px solid #ffff00;
            }
            &:after{
                border-top: 40px solid #ffff00;
            }
        }
        .container{
            padding: 0;
        }
        .head-section{
            .inside-head-section{
                background-image: url(../images/header-bg-mobile.jpg);
                background-size: cover;
                .cloude-img{
                    right: 0;
                    bottom: -11px;
                    img{
                        width: 30%;
                    }
                }
            }
            .logo{
                padding-bottom:30px;
                img{
                    width: 75%;
                    margin: 0 auto;
                }
            }
            .track-on{
                .normal{
                    font-size: 17px;
                    line-height: 16px;
                }
                .semi-title{
                    font-size: 25px;
                    line-height: 30px;
                    padding: 0;
                }
                .title{
                    line-height: normal;
                    font-size: 0;
                }
            } 
            .yellow-title{
                font-size: 12px;
                padding: 5px;
                transform: rotate(-2deg);
                letter-spacing: 5px;
                &:before{
                    border-bottom:27px solid #ffff00;
                }
                &:after{
                    border-top: 27px solid #ffff00;
                }
            }
        } 
        .common-layout{
            .sleep-problem-content{
                padding: 0;
                background-image: none;
                background-color: #e8e9eb;
                .row{
                    .col-lg-8{
                        padding: 0;
                    }
                }
                .discription{
                    padding: 15px;
                    p{
                        font-size: 15px;
                    }
                    h3{
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .titile{
                    font-size: 17px;
                    line-height: 21px;
                    width: 90%;
                    margin: -20px auto 0 auto;
                    text-align: left;
                    padding: 2px 5px;
                    &:before{
                        border-left: 7px solid transparent;
                        border-bottom:46px solid #2b71bb;
                        left: -11px;
                        padding-right: 7px;
                    }
                    &:after{
                        border-right: 5px solid transparent;
                        border-top:46px solid #2b71bb;
                        right: -9px;
                    }
                }
            }
            .discover-solution{
                padding: 20px 0px;
                .solution-title{
                    font-size: 15px;
                    padding-bottom: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                .solution-words{
                    font-size:15px;
                    width: 100%;
                    padding:0 15px 30px 15px;
                }
                .solution-logos{
                    &.solution-logos-logos{
                        display: flex !important;
                    }
                    img:last-child{
                        margin-right: 20px;
                    }
                    img{
                        width: 40%;
                        height: 100%;
                        margin: 0 auto;
                    }
                } 
                .solution-list-box{
                    .col-xs-12{
                        padding: 0;
                        img{
                            width: 100%;
                        }
                    }
                    .list-box-content{
                        padding: 15px;
                        h2{
                            padding: 0;
                            position: relative;
                            top: -9px;
                        }
                        p{
                            padding-top: 0;
                            font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }
            }
        } 
        .brick-off{
            .inside-brick-off{
                padding: 25px 20px;
                background-image: url(../images/6-month-bg-mobile.jpg);
                background-position: right;
                h2{
                    font-size: 40px;
                }
                h3{
                    font-size: 35px;
                }
                .yellow-title-reality{
                    padding: 0;
                    &:after{
                        border-top: 30px solid #ffff00;
                        padding-right: 6px;
                        right: -14px;
                    }
                    &:before{
                        padding-right: 6px;
                        border-bottom: 30px solid #ffff00;
                        left: -14px;
                    }
                }
            }
        } 
        .kevin-box{
            .inside-kevi-box{
                padding: 0px;
                background-size: cover;
                background-color: #e5eaed;
                background-image: none;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                h2,h3, .discription{
                    padding: 15px;
                }
                h2{
                    font-size: 35px;
                    margin: 0;
                    padding-bottom: 0;
                    padding-top: 32px;
                }
                h3{
                    padding-bottom: 0;
                    margin-top: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    br{
                        display: none;
                    }
                }
            }
        } 
        .my-story{
            .best-me{
                background-color: #e5e5e5;
                background-image: none;
            }
            .inside-story{
                margin: 0;
                background-image: none;
                background-color: #ffffff;
                .fix-story{
                    width: 100%;
                    img{
                        margin: 0;
                    }
                    .discription{
                        padding-top: 20px;
                        padding-bottom: 40px;
                        padding-left: 0;
                    }
                }
                .my-story-title{
                    padding-top: 30px;
                    p{
                        margin: 0;
                        padding-bottom: 3px;
                    }
                    h2{
                        font-size: 60px;
                        background-image: none;
                        line-height: 70px;
                        margin-bottom: 0;
                        padding-bottom: 25px;
                    }
                }
            }
            .started-story{
                background-image: url(../images/story-year-ago-mobile.jpg);
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 40px;
                background-size: cover;
                padding-bottom: 180px;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .started-title{
                    line-height: 30px;
                    font-size: 25px;
                    margin-top: 0;
                }
            }
            .have-everything{
                padding: 0;
                .inside-have-everything{
                    margin: 0;
                    padding-top:20px;
                    .everything-list{
                        margin: 20px 0;
                        
                        &:first-child{
                            // margin-top: 40px;
                        }
                        .discriptions{
                            p{
                                padding: 15px !important;
                            }
                            .label{
                                left: 33px !important;
                                top: -71px !important;
                                padding:7px 10px 11px 10px;
                                font-size: 30px;
                                &:before{
                                    border-left: 10px solid transparent;
                                    border-bottom: 46px solid #ffff00;
                                    left: -17px;
                                }
                                &:after{
                                    border-top: 10px solid transparent;
                                    border-top: 46px solid #ffff00;
                                    right: -17px;
                                }
                            }
                        } 
                    }
                }
                .col-inside-have{
                    padding: 0 !important;
                }
                .everything-btn{
                    padding-top: 15px;
                    padding-bottom: 25px;
                    picture{
                        img{
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .best-me{
                padding-bottom: 0;
                .inside-best-me{
                    .col-xs-12{
                        padding: 0;
                    }
                    .best-me-content{
                        padding:0px 15px 15px 15px;
                        .top-titles{
                            padding-right: 12px;
                        }
                    }
                    .best-me-img{
                        img{
                            height: auto;
                        }
                    } 
                } 
                .my-job-me{
                    margin:0px;
                    background-color: #253540;
                    background-image: none;
                    width: 100%;
                    .row{
                        margin: 0;
                        .col-xs-12{
                            padding: 0;
                        }
                    }
                    .job-img{
                        margin: 0;
                        img{
                            height: auto;
                            padding: 0 25px;
                        }
                    } 
                    .discription{
                        padding: 15px;
                        h2{
                            text-align: center;
                        }
                        h3{
                            text-align: center;
                        }
                    }
                }
            } 
            .slowly-breaking{
                padding: 15px 0px 0 0px;
                background-image: none;
                background-color: #242426;
                .inside-best-me{
                    .col-xs-12.pl-0{
                        padding: 0;
                    }
                    .padding-t-l{
                        padding: 15px;
                    }
                    h2{
                        font-size: 25px;
                        line-height: 27px;
                    }
                    .discription{
                        padding: 0;
                    }
                    .img{
                        img{
                            height: auto;
                            width: 100%;
                        }
                    } 
                } 
            } 
            .crazy-driving{
                // padding: 15px;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .top-titles{
                    padding: 15px 15px 0 15px;
                }
                .discription{
                    padding: 15px;
                }
            } 
        } 
        .new-reality{
            .devider{
                display: none;
            }
            .main-new-reality{
                .inside-new-reality{
                    padding: 25px 20px;
                    h3{
                        font-size: 35px;
                    }
                    .yellow-title-reality{
                        padding: 0;
                        margin: 0 auto 0 auto;
                        letter-spacing: 5px;
                        line-height: normal;
                        &:before{
                            border-bottom: 27px solid #ffff00;
                            padding-right: 5px;
                            left:-13px;
                        }
                        &:after{
                            border-top: 27px solid #ffff00;
                            padding-right: 5px;
                            right:-13px;
                        }
                    }
                }
                .new-reality-box{
                    padding: 10px 0px 0 0px;
                    .row{
                        .col-xs-12{
                            padding: 0;
                        }
                    }
                    .top-titles{
                        font-size: 25px;
                        padding: 0 15px !important;
                        br{
                            display: none;
                        }
                    }
                    .pull-left.col-xs-12{
                        padding: 0;
                    }
                    .img{
                        padding-right: 0;
                        padding-left: 0;
                        img{
                            width:100%;
                        }
                    }
                    .title{
                        font-size: 24px;
                        line-height: 29px;
                        padding-right: 0;
                        padding: 0 15px 10px 15px;
                    }
                    .discription{
                        padding: 0 15px !important;
                        // padding-right: 0;
                        p{
                            strong{
                                color: #ffffff;
                            }
                        }
                    }
                }
                .impacts-wrose-box{
                    width: 100%;
                    padding: 15px 0;
                    .mobile-padding-0{
                        padding: 0;
                    }
                    .img{
                        padding-top: 10px;
                        position: relative;
                        width:auto;
                    }
                    .top-titles{
                        padding:0;
                    }
                    .sub-title{
                        padding:0;
                        line-height: 20px;
                        margin-bottom: 20px;
                    }
                    .discription{
                        padding:0;
                        p{
                            strong{
                                color: #ffffff;
                            }
                        }
                    }
                } 
            } 
        } 
        .profestional-help{
            .inside-profestional-help{
                background: none;
                background-color: #ebecf0;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .top-titles{
                    padding: 15px 15px 0 15px;
                    line-height: 32px;
                    font-size: 25px;
                }
                .discription{
                    padding: 5px 0 15px 0;
                    p{
                        padding: 0 15px 0 15px;
                    }
                    .mobile-help-img{
                        padding-bottom: 18px;
                    }
                }
            } 
            .risk-for-hours{
                padding: 0;
                .risk-for-hours-box{
                    width: 100%;
                    border: none;
                    padding: 30px 20px 20px 20px;
                    h3{
                        font-size: 18px;
                    }
                    .risk-list{
                        width: 90%;
                        ul{
                            li{
                                font-size: 15px;
                            }
                        }
                    }
                    .fucntion-last-title{
                        width: 100%;
                        font-size: 17px;
                    }
                }
            } 
        } 
        .poor-risk{
            .row{
                .col-xs-12{
                    padding: 0;
                }
            }
            .top-titles{
                background-image: url(../images/nil-sleep-logo-mobile.jpg);
                background-size: cover;
                padding: 200px 15px 15px 15px;
                color: #ffffff;
            }
            .discription{
                padding: 15px;
            }
        }
        .take-action{
            .take-action-title{
                padding: 30px 10px;
                .yellow-title{
                    margin-top: 0px;
                    font-size: 20px;
                    padding: 0px;
                    letter-spacing: 5px;
                    &:before{
                        border-bottom: 30px solid #ffff00;
                        padding-right: 5px;
                        left: -10px;
                        border-left: 5px solid transparent;
                    }
                    &:after{
                        border-top: 30px solid #ffff00;
                        border-right: 5px solid transparent;
                        padding-right: 2px;
                        right: -5px;
                    }
                }
                h3{
                    font-size: 25px;
                    width: 60%;
                    margin: 0 auto;
                    padding-bottom: 7px;
                    line-height: 21px;
                }
                h2{
                    font-size: 35px;
                }
            } 
            .figure-section{
                background-color: #ffffff;
                background-image: none;
                .title-our{
                    text-align: center;
                    line-height: 42px;
                }
                .by-devices{
                    font-size: 15px;
                    line-height: 21px;
                    width: 84%;
                    margin: 0 auto;
                    padding: 5px;
                    &:before{
                        border-left: 7px solid transparent;
                        border-bottom: 52px solid #2b71bb;
                        left: -17px;
                    }
                    &:after{
                        border-right: 7px solid transparent;
                        border-top: 52px solid #2b71bb;
                        right: -17px;
                    }
                }
            }
            .level-section{
                padding: 0;
                .device-img img:last-child{
                    padding-top: 0;
                }
                .row{
                    padding: 25px 0px 0 0px;
                }
                .tricky{
                    font-size: 23px;
                    text-align: center;
                }
                .discription{
                    // padding: 0 15px;
                }
                .blue-light-box{
                    background-image: none;
                    background-color: #3e4954;
                    padding: 30px 10px;
                    margin-bottom: 0;
                    .title{
                        h3{
                            font-size: 18px;
                            width: 97%;
                        }
                    } 
                    .source-blue-light{
                        img{
                            width: 90%;
                        }
                    }
                }
                .mobile-padding-none{
                    padding: 0;
                }
                .awake-alert{
                    .discription{
                        P:last-child{
                            padding-bottom: 15px;
                        }
                    } 
                } 
                .stop-producting{
                    h3{
                        font-size: 18px;
                        width: 83%;
                        margin: 0 auto 10px auto; 
                        text-align: center;
                        &:before{
                            border-bottom: 77px solid #2b71bb;
                        }
                        &:after{
                            border-top: 77px solid #2b71bb;
                        }
                    }
                } 
            } 
        } 
        .figure-section-mobile{
            background-image: none;
            .row{
                .col-xs-12{
                    padding: 0;
                }
            }
            .figure-section-top-mobile{
                background: #e9ecef;
                padding: 15px 15px 251px 15px;
                background-image: url(../images/action-bg-mobile.jpg);
                background-size: cover;
                background-position: center;
            }
            .figure-section-content-mobile{
                background: #ffffff;
                padding: 15px;
            }
        }
        .total-sanse{
            .inside-total-sanse{
                padding:25px 0 0px 0;
                .try-your-self-img{
                    // padding: 15px;
                    padding: 38px 10px 60px 10px;
                    width: 100% !important;
                    background-size: cover !important;
                    .self-title{
                        font-size: 25px;
                        margin: 0 auto 25px auto;
                    }
                    .self-title-list ul{
                        width: 100%;
                        li{
                            font-size: 15px;
                            display: flex;
                            line-height: 20px;
                            padding-bottom: 15px;
                        }
                    }
                }
                .total-sanse-last{
                    padding: 15px;
                    h2{
                        background-image: url(../images/blue-line.png);
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        font-size: 23px;
                        padding-bottom: 12px;
                        margin-bottom: 20px;
                        span{
                            background-image: none;
                        }   
                    }
                }
                .digital-boxes{
                    padding: 15px 0 0px 0;
                    .row{
                        width: 100%;
                        .col-xs-12{
                            padding: 0;
                            margin-bottom: 15px;
                            .solution-box{
                                &.first{
                                    .yellow-title{
                                        padding: 7px 30px 14px 17px;
                                    }
                                }
                                &.second{
                                    .yellow-title{
                                        right: 45px;
                                        left: unset;
                                    }
                                }
                                &.third{
                                    .yellow-title{
                                        right: 65px;
                                        left: unset;
                                        padding: 7px 5px 15px 5px;
                                        &:after{
                                            right: -25px;
                                            border-top: 50px solid #ffff00;
                                        }
                                        &:before{
                                            left: -25px;
                                            border-bottom: 50px solid #ffff00;
                                        }
                                    }
                                }
                                .yellow-title{
                                    left: 40px;
                                    bottom: 13px;
                                    padding: 7px 10px 14px 10px;
                                    font-size: 30px;
                                    &:before{
                                        border-bottom: 49px solid #ffff00;
                                    }
                                    &:after{
                                        border-top: 49px solid #ffff00;
                                    }
                                }
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .total-sanse-top{
                    padding: 15px;
                    .row{
                        .col-xs-12{
                            padding: 0;
                        }
                    }
                    h2{
                        text-align: center;
                        font-size: 23px;
                    }
                    .total-sanse-img{
                        padding-bottom: 17px;
                    }
                } 
            }
        } 
        .better-way{
            .claim-better-way{
                background-image: none;
                background-color: #ffffff;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                h3{
                    width: 100%;
                    font-size: 22px;
                    line-height: 28px;   
                    padding: 15px;             
                }
                .top-titles{
                    padding: 15px 15px 0 15px;
                }
                .discription{
                    padding: 15px 15px 0px 15px;
                    p{
                        &:last-child{
                            padding: 0;
                        }
                    }
                }
                .better-way-mobile{
                    padding-top: 10px;
                }
            } 
            .claim-your-glasees{
                .glass-last-coments{
                    padding: 15px;
                    p{
                        text-align: center;
                        font-size: 15px;
                        line-height: normal;
                        padding-bottom: 4px;
                    }
                }
                .discription{
                    width: 100%;
                    padding: 15px;
                }
                .claim-glass-mobile{
                    padding-top: 10px;
                }
                .first-thought{
                    width: 100%;
                    background-color: #ffffff;
                    padding: 40px 10px;
                    h3{
                        font-weight: 500;
                    }
                    h2{
                        line-height: 22px;
                        font-size: 17px;
                        padding-top: 15px;
                    }
                    ul{
                        width: 90%;
                        li{
                            font-size: 15px;
                            background-position: left top;
                            line-height: 21px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        .blue-light-phenomena{
            .inside-blue-light-phenomena{
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                .mobile-padding-none{
                    padding-top: 20px;
                } 
            }
            .try-myself{
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .discription{
                    padding: 15px;
                }
                .floating-img{
                    margin: 0;
                    img{
                        height: auto;
                    }
                }
                .blue-title{
                    margin: 30px auto 15px auto;
                    // width: 90%;
                    font-size: 17px;
                    padding: 2px;
                    &:before{
                        border-bottom: 30px solid #2b71bb;
                        left: -7px;
                        padding-right: 4px;
                        border-left: 5px solid transparent;
                    }
                    &:after{
                        border-top: 30px solid #2b71bb;
                        padding-right: 5px;
                        border-right: 4px solid transparent;
                        right: -7px;
                    }
                }
            }
        } 
        .dream-elements{
            .insdie-dream-element{
                &.padding-all{
                    padding:25px 0px; 
                }
                .dream-elements-box,.floating-img, .claim-btn-discription, .clinical-study{
                    padding: 15px;
                }
                .top-titles{
                    text-align: left;
                    padding-bottom: 20px;
                    padding: 15px;
                }
                .dream-elements-box{
                    width: 100%;
                    margin: 30px 0 0 0;
                    ul{
                        padding: 30px 5px;
                        li{
                            background-position: top left;
                            font-size: 15px;
                            line-height: 24px;
                            margin-bottom: 15px;
                            background-size: 40px
                        }
                    }
                } 
                .claim-btn-discription{
                    .discription{
                        width: 100%;
                        margin: 0 auto;
                    }
                } 
                .clinical-study{
                    margin-bottom: 0;
                    .discription{
                        width: 100%;
                        p{
                            text-align: left;
                            width: 100%;
                        }
                    }
                    .clinical-titles{
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 15px;
                    }
                }
                .sleeping-btr{
                    width: 100%;
                    padding: 25px 0;
                    .top-titles{
                        margin-bottom: 0;
                    }
                    .discription{
                        padding: 5px 15px 0 15px;
                        p{
                            text-align: left;
                            padding-bottom: 0;
                        }
                    }
                } 
                .sleep-patter{
                    border: none;
                    padding: 30px 15px;
                    .top-section{
                        h2{
                            font-size: 18px;
                            line-height: 20px;
                            padding-bottom: 30px;
                        }
                    } 
                    .inside-sleep-patter{
                        padding-bottom: 30px;
                    }
                }
                .company-list{
                    padding: 0;
                    .row{
                        margin: 0;
                        .col-xs-12{
                            padding: 0;
                        }
                    }
                    .floating-img{
                        padding: 0;
                    }
                    .company-list-discrition{
                        padding: 15px 15px 0 15px;
                        .blue-title{
                            font-size: 18px;
                            padding: 0;
                            width: fit-content;
                            margin: 10px 16px 20px 8px;
                            &:before{
                                padding-right: 4px;
                                border-left: 5px solid transparent;
                                border-bottom: 26px solid #2b71bb;
                                top: 0px;
                                left: -9px;
                            }
                            &:after{
                                padding-right: 5px;
                                border-right: 4px solid transparent;
                                border-top: 26px solid #2b71bb;
                                position: absolute;
                                top: 0px;
                            }
                        }
                    }
                    .company-glass{
                        padding-top: 0;
                        padding-bottom: 0;
                        h2{
                            font-size: 23px;
                            line-height: 28px;
                            span{
                                padding-bottom: 0;
                            }
                        }
                    }
                } 
            }
        }
        .real-people{
            .inside-real-people{
                .discription p{
                    text-align: left;
                }
            } 
            .real-people-box{
                .blank-box{
                    margin-bottom: 15px;
                }
            }
            .anti-blue-light-content{
                padding: 0;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .discription{
                    padding: 25px 15px 15px 15px;
                }
            }
            .want-that-life{
                .row{
                    padding-bottom: 0px;
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .top-titles{
                    margin: 0;
                }
                .inside-want-that-life{
                    padding-left: 0;
                    margin-bottom: 20px;
                    .life-imgs{
                        width: 30%;
                        .life-img{
                            img{
                                width: 70px;
                            }
                        }
                    }
                    .life-tag{
                        padding: 20px 10px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 28px;
                        background-size: 85px 43px;
                    }
                    .discription{
                        width: 70%;
                    }
                }
            }
            .give-anythings{
                padding: 25px 0px 0px 0px;
                .inside-anything{
                    padding: 0;
                    .inside-give-anything{
                        .top-titles{
                            width: 100%;
                        }
                    }
                    .floating-img{
                        img{
                            padding: 25px;
                        }
                    } 
                }
                .looking-for-solution{
                    background-color: #e7ebf1;
                    padding: 30px 5px;
                    .solution-lists p{
                        font-size: 15px;
                        line-height: 24px;
                    }
                    .discription{
                        width: 100%;
                        padding: 0 15px 15px 15px;
                    }
                    .row{
                        width: 100%;
                        .col-xs-12{
                            width: 50%;
                        }
                    }
                }
            } 
            .bonuses{
                .sleep-mobile-img{
                    img{
                        padding: 0px 30px 0 30px;
                    }
                }
                .insdie-bonuses{
                    h2.sleep-cls{
                        font-size: 16px;
                    }
                    h2{
                        margin-top: -26px;
                    }
                    h2, h3{
                        padding: 0 15px;
                        font-size: 25px;
                        text-align: left;
                        line-height: 26px;
                    }
                    .sleep-cls{
                        padding: 0;
                    }
                    .row{
                        .col-xs-12{
                            padding: 0;
                        }
                    }
                    .discription{
                        padding:30px 0;
                        p{
                            &:last-child{
                                padding-bottom: 0;
                            }
                        }
                    }
                    ul{
                        width: 100%;
                        padding-left: 0;
                        padding-bottom: 10px;
                        li{
                            background-size: 22px 22px;
                        }
                    }
                } 
            } 
        }

        .real-people{
            .give-anythings{
                .looking-for-solution{
                    .solution-lists img{
                        padding: 11px;
                        width: 100px;
                        height: 100px;
                        object-fit: contain;
                    }
                } 
            } 
            .bonuses .insdie-bonuses{
                ul li{
                    font-size: 15px;
                    line-height: 20px;
                }
                .row{
                    width: 90%;
                }
            } 
        } 
        .thousands{
            &.slepping-btr-content{
                .need-to-feel{
                    background-size: contain !important;
                    .cloude-img-better{
                        position: absolute;
                        left: 0;
                        bottom: 101px;
                        img{
                            width: 36%;
                        }
                    }
                    .get-title{
                        font-size: 112px;
                        line-height: 62px;
                    }
                    h4{
                        font-size: 30px !important;
                    }
                    h3{
                        font-size: 72px !important;
                    }
                    .get-title{
                        padding-top:10px;
                    }
                }
            }
            &.anti-blue-light-dreamelements{
                .need-to-feel{
                    .get-title{
                        font-size: 34px;
                        line-height: 38px;
                        width: 98%;
                    }
                    h2{
                         font-size:30px;
                         padding-bottom: 0; 
                    }
                     h4{
                         font-size: 34px;
                         padding-bottom: 10px;
                         line-height: 38px;
                     }
                     h3{
                        font-size: 65px;
                        letter-spacing: -0.25px;
                        line-height: 11px;
                     }
                     .yellow-title{
                         font-size: 17px;
                         text-align: center;
                         width: 84%;
                         margin: 15px auto 0px auto;
                         &:before{
                             content: '';
                             padding-right: 15px;
                             border-left: 10px solid transparent;
                             border-bottom: 58px solid #ffff00;
                             position: absolute;
                             top: 0px;
                             left: -23px;
                         }
                         &:after{
                             content: '';
                             padding-right: 15px;
                             border-right: 10px solid transparent;
                             border-top: 58px solid #ffff00;
                             position: absolute;
                             top: 0px;
                             right: -23px;
                         }
                     }
                } 
             }
            .nothing-to-loss{
                .white-title{
                    font-size: 18px;
                    padding: 2px;
                    &:after{
                        border-top: 30px solid #ffffff;
                        padding-right: 10px;
                    }
                    &:before{
                        border-bottom: 30px solid #ffffff;
                        padding-right: 10px;
                    }
                }
            } 
            .inside-thousand{
                padding:40px 10px;
                background-image: url(../images/thousand-bg.jpg);
                background-size: cover;
                .cloude-img{
                    display: none;
                }
                h2{
                    font-size: 30px;
                    padding: 0;
                }
                h3{
                    font-size: 35px ;
                    padding: 0;
                    line-height:31px;
                }
                h4{
                    font-size: 50px ;
                    padding: 0;
                    line-height: 39px;
                }
                .spect{
                    margin: 0;
                    padding-top: 10px;
                }
                .yellow-title{
                    padding: 2px;
                    margin-bottom: 4px;
                    font-size: 15px;
                    &:before{
                        padding-right: 6px;
                        border-left: 3px solid transparent;
                        border-bottom: 25px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        left: -8px;
                    }
                    &:after{
                        padding-right: 6px;
                        border-right: 3px solid transparent;
                        border-top: 25px solid #ffff00;
                        position: absolute;
                        top: 0px;
                        right: -8px;
                    }
                }
            }
            .sixty-day-gauranty{
                .sixty-title{
                    font-size: 40px;
                    line-height: 55px;
                }
                .top-titles{
                    margin: 0;
                    padding-bottom: 5px;
                }
                .sixty-logo{
                    padding-bottom: 20px;
                    padding-top: 5px;
                    img{
                        width: 63%;
                    }
                }
                .discription{
                    width: 100%;
                }
            } 
            .you-need{
                padding: 50px 10px 20px 10px;
                background-image: url(../images/refreshed-bg-mobile1.jpg);
                background-size: cover;
                .cloude-img{
                    img{
                        width: 39%;
                        margin: 0 0 0 auto;
                    }
                }
                .glass-img img{
                    margin-top: -20px;
                }
                h2{
                    font-size: 50px ;
                    color: #ffffff;
                    line-height: 15px;
                }
                h3{
                    font-size: 25px;
                    padding-bottom: 0;
                }
                .tonight{
                    font-size: 40px;
                    padding-right: 0;
                    padding-bottom: 0;
                }
            } 
            .need-to-feel{
                padding: 20px 10px;
                background-image: url(../images/refreshed-bg-mobile.jpg);
                background-size: cover !important;
                &.feel-refreshed-section {
                    .cloude-img-feel{
                        top: 123px;
                        img{
                            width: 29%;
                            margin: 0 0 0 auto;
                        }
                    }
                }
                .cloude-img{
                    display: none;
                }
                .and{
                    padding: 5px;
                    font-size: 22px;
                }
                .get-title{
                    font-size: 41px;
                    padding-bottom: 8px;
                }
                h2{
                    font-size: 23px;
                    line-height: 20px;
                    padding-bottom: 10px;
                }
                h3{
                    font-size: 45px;
                    line-height: 32px;
                }
                .glass-img{
                    margin-top: 10px;
                }
            }
            .faq{
                padding:20px 0 0 0;
                .top-titles{
                    padding: 0 15px;
                    text-align: left;
                }
                .panel-body{
                    border: none;
                    font-size: 15px;    
                    line-height: 24px;
                }
                .panel-group{
                    margin: 0;
                    .panel{
                        margin: 0;
                        border-bottom: 1px solid #d3cfcf;
                        .panel-heading{
                            border: none;
                            border-radius: 0;
                        }
                    } 
                    &#accordion{
                        width: 100%;
                        .panel-heading [data-toggle="collapse"]:after{
                            position: absolute;
                            top: 20px;
                            right: 15px;
                        }
                        .panel-heading{
                            padding-right: 30px;
                            position: relative;
                            .panel-title{
                                font-size: 15px;
                                display: flex;
                                padding-right: 25px;
                                line-height: 23px;
                            }
                            .accordion-toggle.collapsed{
                                .panel-title{
                                    font-size: 15px;
                                    display: flex;
                                    padding-right: 25px;
                                }
                            } 
                        } 
                    } 
                } 
            }
        } 
        .fooot{
            .menus{
                display: inline-block !important;
                text-align: center;
                padding-bottom: 20px;
                li{
                    float: left;
                    display: contents;
                    a{
                        font-size: 14px;
                    }
                    a:after{
                        padding: 0 10px;
                    }
                }
            }
            .copy-right{
                line-height: 23px;
                font-size: 14px;
            }
        }
        .sleep-patter-box{
            .inside-sleep-patter p{
                padding: 0 20px !important;
                margin-bottom: 20px;
                font-size: 15px !important;
            }
            .slick-dots{
                padding: 0;
                list-style: none;
                display: flex !important;
                justify-content: center;
                li{
                    button{
                        border: none;
                        background: transparent;
                        font-size: 23px;
                        font-family: 'Yellowtail', cursive;
                        color: #15959f;
                        &:focus{
                            outline: 0;
                        }
                    }
                    &.slick-active{
                        background-color: #15959f;
                        button{
                            color: #ffffff;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    margin-right: 10px;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .profestional-help{
            .poor-risk{
                padding-right: 0;
            }
        }
        .menus li a{
            font-size: 14px;
        }
    } 
}


#mobile-popups{
    position: fixed;
    bottom: 0;
    width: 100%;
    .mobile-popups{
        display:none;
        .item-caption{
            background-color: #0c416a;
            padding: 8px 10px;
        }
        position: relative;
        .my-glass-btn{
            a{
                color: #ffffff;
                text-decoration: none;
            }
            position: relative;
            cursor: pointer;
            background-color: #0c9449;
            font-family: 'Nunito Sans', sans-serif;
            font-size: 18px;
            color: #ffffff;
            font-weight: 800;
            width: 98%;
            border-radius: 60px;
            margin: 0 auto 0px auto;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.5px;
            line-height: 27px;
            padding:13px 30px 13px 12px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            img{
                // padding-left: 15px;
                // width: 45px;
                padding-left: 0;
                width: 21px;
                position: absolute;
                right: 16px;
                top: 20px;
            }
        }
        // .hurry-up{
        //     font-family: 'Nunito Sans', sans-serif;
        //     font-weight: 700;
        //     color: #ffffff;
        //     font-size: 14px;
        //     line-height: 27px;
        //     letter-spacing: 2px;
        //     text-align: center;
        //     font-style: italic;
        //     span{
        //         text-transform: uppercase;
        //         background-color: #fff000;
        //         color: #000000;
        //         font-style: italic;
        //         padding: 2px 7px;
        //     }
        // }
        // .close-tog{
        //     position: absolute;
        //     right: 10px;
        //     top: 5px;
        //     img{
        //         width: 25px;
        //     }
        // }
        &.nav-down{
            display: none;
        }
        &.nav-up{
            display: block;
        }
    }
}



@media (min-width:768px) and (max-width:959px){

    .index-page{
        .head-section{
            .track-on{
                .normal{
                    font-size:40px;
                }
                .title{
                    img{
                        width: 87%;
                    }
                } 
                .semi-title{
                    font-size: 59px;
                }
            } 
        }
    } 

    .index-page{
        .container{
            .row{
                margin: 0;
            }
        }
        .thousands.slepping-btr-content{
            .need-to-feel{
                .get-title{
                    transform: none;
                }
            } 
        }
        .common-layout{
            .discover-solution{
                padding: 40px 20px;
                .solution-words{
                    width: 70%;
                }
                .solution-list-box{
                    .row{
                        .col-sm-4{
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                    }
                }
            }
            .sleep-problem-content{
                background-size: cover;
                padding: 20px 20px 7px 20px;
                background-image: url(../images/sleep-tab.jpg);
                p.tab-discri{
                    padding-right: 201px;
                }
            }
            .kevin-box{
                .inside-kevi-box{
                    padding: 20px 20px 90px 20px;
                }
            }
        }
        .padding-t-l{
            padding-left: 20px;
        }
        .take-action{
            .tab-level-sectoin{
                padding-left:20px;
                padding-right:20px;
                .device-img img:last-child{
                    padding-top: 0;
                }
                .row{
                    margin-left: -15px;
                    margin-right: -15px;
                }
                .stop-producting{
                    h3{
                        margin: 0 auto 17px auto;
                        text-align: center;
                        width: 93%;
                        position: relative;
                        right: 3px;
                    }
                }
            }
            .level-section{
                .awake-alert{
                    padding-left:20px;
                    padding-right:20px;
                    .row{
                        margin-left: -15px;
                        margin-right: -15px;
                    }
                }
                &.padding-all{
                    padding-left: 0;
                    padding-right: 0;
                }
                .blue-light-box{
                    background-image: none;
                    background-color: #3e4954;
                    .title{
                        h2{
                            margin: 0 auto 31px auto;
                            width: 50%;
                            line-height: 22px;
                        }
                        h3{
                            width:90%;
                        }
                    } 
                }
            } 
            .figure-section{
                .row{
                    .col-sm-6{
                        padding-left: 0;
                    }
                }
            }
        }
        .total-sanse{
            .total-sanse-top{
                .total-sanse-img img{
                    width: 85%;
                }
                .row{
                    .col-sm-6{
                        padding-left: 0;
                    }
                }
            }
            .total-sanse-last{
                .discription{
                    width:90%;
                    margin:0 auto;
                }
                h2{
                    width:85%;
                    margin:0 auto;
                    padding-bottom: 31px;
                    span{
                        padding-bottom: 6px;
                    }
                }
            }
            .digital-boxes{
                .solution-box{
                    .yellow-title{
                        left:8px;
                    }
                }
                .row{
                    .col-sm-4{
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }
            }
            .inside-total-sanse{
                .try-your-self-img{
                    .self-title-list{
                        ul{
                            width: 59% !important;
                        }
                    } 
                } 
            } 
        }
        .better-way{
            .claim-better-way{
                background-image: url(../images/better-way-tab.jpg);
                .row{
                    .col-sm-12{
                        padding:0;
                        .discription{
                            padding-right: 37%;
                        }
                    }
                }
            }
            .claim-your-glasees{
                .first-thought{
                    background-color: #ffffff;
                    background-image: none;
                    padding:30px 10px;
                    h2{
                        line-height: 30px;
                        width: 80%;
                        margin: 0 auto;
                        padding-bottom:10px;
                    }
                    ul{
                        width:85%;
                    }
                    h3{
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: bold;
                    }
                }
                .discription{
                    width: 95%;
                }
            }
        } 
        .blue-light-phenomena{
            .inside-blue-light-phenomena{
                .top-titles{
                    font-size: 38px;
                }
            }
            .try-myself{
                .floating-img{
                    img{
                        width:100%;
                    }
                } 
            } 
        }
        .dream-elements{
            .insdie-dream-element{
                padding-left:0;
                padding-right:0;
                .top-titles, .floating-img, .dream-elements-box, .claim-btn-discription, .sleeping-btr, .sleep-patter, .company-list{
                    padding-left:20px;
                    padding-right:20px;
                }
                .company-list-discrition{
                    .discription-second{
                        p{
                            padding-bottom: 25px;
                            &:last-child{
                                padding-bottom:0;
                            }
                        }
                    }
                }
                .floating-img{
                    text-align: left;
                    img{
                        width: 94%;
                    }
                } 
                .sleep-patter{
                    .sleep-patter-box{
                        .inside-sleep-patter{
                            display: flex;
                            p{
                                text-align: left;
                                padding-left:30px;
                                padding-right:15px;
                                &.left-less{
                                    padding-left:20px;
                                }
                            }
                        }
                    }
                }
                .dream-elements-box{
                    width: 95%;
                    margin: 60px auto 0 auto;   
                }
                .top-titles{
                    margin-bottom: 40px;
                }
                .sleeping-btr{
                    width:93%;
                }
                .company-list{
                    .company-glass{
                        h2{
                            line-height: 36px;
                        }
                    } 
                    .row{
                        .col-sm-5{
                            padding-left:0;
                        }
                        .col-sm-7{
                            padding-left: 0;
                        }
                    }
                    .floating-img{
                        padding:0;
                    }
                }
            } 
        } 
        .real-people{
           .want-that-life{
            padding-left: 12px;
            padding-right: 12px;
            .top-titles{
                margin-bottom: 10px;
            }
                .inside-want-that-life{
                    .life-imgs{
                        img{
                            width: 70%;
                        }
                    }
                    .life-tag{
                        font-size: 30px;
                        padding: 25px;
                        background-size: contain;
                    }
                    .discription{
                        padding-left: 20px;
                        p{
                            &:last-child{
                                padding-bottom: 0;
                            }
                        }
                    }
                } 
                .imagine-life{
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .row{
                    .col-sm-6{
                        padding-left: 9px;
                    }   
                }
           } 
            .real-people-box{
                .blank-box{
                    width:78%;
                    margin:0 auto 30px auto;
                    iframe{
                        height: 250px;
                    }

                }
            }
            .give-anythings{
                padding-left:0;
                padding-right:0;
                padding-bottom:0;
                .looking-for-solution .solution-lists img{
                    height: 75px;
                    width: auto;
                }
                .top-titles{
                    padding-left:0px;
                    padding-right:20px;
                }
                .inside-anything{
                    padding-left:20px;
                    padding-right:20px;
                    .row{
                        .col-sm-6{
                            padding-left: 0;
                        }
                    }
                }
                .looking-for-solution{
                    background-image: none;
                    background-color:#e7ebf1;
                    .top-titles{
                        width: 95%;
                    }
                    .discription{
                        width:88%;
                    }
                } 
            } 
            .slepping-btr-content{
                .get-title{
                    font-size:92px !important;
                    margin-top:-21px;
                }
            }
            .bonuses{
                .insdie-bonuses{
                    h2.sleep-cls{
                        padding-top: 60px;
                    }
                    .discription{
                        padding-top: 0;
                        padding-bottom: 20px;
                        width: 80%;
                        margin: 0 auto;
                        text-align: center;
                    }
                    .yelloe-bonus{
                        font-size: 75px;
                        line-height: 65px;
                        &::before{
                            border-bottom: 74px solid #ffff00;
                        }
                        &::after{
                            border-top: 74px solid #ffff00;
                        }
                    }
                    .tab-pading-0{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
        .thousands.slepping-btr-content{
            .need-to-feel{
                background-image: url(../images/better-bg-img-tab.jpg);
                background-position: bottom -66px center;
                .cloude-img-better{
                    position: absolute;
                    left: -12px;
                    bottom: 25%;
                }
            }
        } 
        .thousands.anti-blue-light-dreamelements{
            .need-to-feel{
                .cloude-img{
                    display:block;
                    top: 142px;
                }
                .get-title{
                    font-size: 46px;
                    padding-bottom: 9px;
                }
                h2{
                    font-size:48px;
                    padding-bottom:2px;
                    line-height: 24px;
                }
                h4{
                    font-size:60px;
                    padding-bottom: 0;
                }
                h3{
                    font-size:160px !important;
                }
            } 
        } 
        .thousands{
            .inside-thousand{
                background-image: url(../images/wake-up-bg-tab.jpg);
                .cloude-img{
                    position: absolute;
                    right: 0;
                    top: 114px;
                }
                h2{
                    font-size:42px;
                    padding-bottom:10px;
                }
                h3{
                    font-size:53px;
                }
                h4{
                    font-size:123px;
                    padding-top:0;
                }
            } 
            .sixty-day-gauranty{
                .top-titles{
                    width: 80%;
                    margin: 0 auto 20px auto;
                }
                .discription{
                    width:93%;
                }
            }
            .you-need{
                background-image: url(../images/you-need-bg-tab.jpg);
                background-size: cover;
                .cloude-img{
                    top: 35px;
                    img{
                        width: 54%;
                    }
                }
                h3{
                    font-size: 59px;
                    padding-bottom:0;
                }
                h2{
                    font-size:136px;
                }
            } 
            .faq .panel-title{
                display: flex;
                padding-right: 40px;
            }
            .faq #accordion{
                width:100%;
                .panel-heading .accordion-toggle.collapsed .panel-title{
                    display:flex;
                }
            }
            .need-to-feel{
                .get-title{
                    font-size: 48px;
                    padding-bottom: 0;
                }
                h2{
                    font-size:61px;
                    padding-bottom: 5px;
                }
                h3{
                    font-size:121px;
                    &.tab-title{
                        font-size:126px;
                    }
                }
                .and{
                    font-size: 37px;
                    padding:12px 0;
                }
            } 
        }
        .profestional-help{
            .risk-for-hours{
                .risk-for-hours-box {
                    width: 95%;
                }
            } 
            .poor-risk{
                padding-right: 25px;
                padding-left: 25px;
            }
        } 
        .my-story{
            .slowly-breaking{
                .inside-best-me{
                    .discription{
                        padding-right: 15px;
                        padding-left: 5px;
                    }
                } 
            } 
        } 
        .new-reality{
            .main-new-reality{
                .impacts-wrose-box{
                    .tab-impacts-wrose-box{
                        &.row{
                            .col-sm-12{
                                padding-right: 0;
                            }
                        }
                        .discription{
                            background-image: url(../images/reality1-tab.png);
                            background-repeat: no-repeat;
                            background-position: top right;
                        }
                    }
                }
            } 
        } 
    } 
    .index-page .my-story .started-story{
        padding-top: 20px;
    }
    .index-page .my-story .best-me .inside-best-me .best-me-img img{
        display: none;
    }
    .index-page .my-story .best-me .inside-best-me .best-me-img img{
        padding-left: 35px;
    }
    .index-page .new-reality .main-new-reality .new-reality-box{
        padding-left: 20px;
    }
    .index-page .new-reality .main-new-reality .new-reality-box .img{
        padding: 0;
    }
    .index-page .profestional-help .risk-for-hours .risk-for-hours-box .risk-list{
        width: 50%;
    }
    // .index-page .take-action .level-section{
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
    .index-page .total-sanse .inside-total-sanse{
        padding: 40px 26px;
    }
    .index-page .total-sanse .inside-total-sanse .total-sanse-top h2{
        line-height: 35px;
    }
    .index-page .total-sanse .inside-total-sanse .digital-boxes .row{
        width: 100%;
    }
    .index-page .padding-all{
        padding-left: 20px;
        padding-right: 20px;
    }
    .index-page .better-way .claim-your-glasees .my-glass-btn{
        width: 75%;
    }
    .index-page .better-way .claim-your-glasees .first-thought{
        background-size: cover;
    }
    .index-page .blue-light-phenomena .try-myself .floating-img{
        margin: 0;
    }
    .index-page .blue-light-phenomena .try-myself .discription{
        padding-left: 20px;
    }
    .index-page .blue-light-phenomena .try-myself .blue-title{
        margin-left: 35px;
    }
    .index-page .new-reality .main-new-reality .impacts-wrose-box{
        width: 85%;
    }
    .index-page .new-reality .main-new-reality .impacts-wrose-box .img{
        width: 100%;
        padding-top: 25%;
    }
    .index-page .real-people .want-that-life .inside-want-that-life{
        padding-left: 0;
    }
    .index-page .thousands.slepping-btr-content .need-to-feel .arrow-custom img{
        width: 100%;
    }
    .index-page .real-people .bonuses .insdie-bonuses .row{
        width: 95%;
        margin: 0 auto;
    }
}


@media (min-width:960px) and (max-width:1024px){
    .index-page .kevin-box .inside-kevi-box{
        background-size: cover;
    }
    .index-page .my-story .started-story{
        background-size: cover;
    }
    .index-page .profestional-help .risk-for-hours .risk-for-hours-box .risk-list{
        width: 45%;
    }
    .index-page .profestional-help .risk-for-hours .risk-for-hours-box .fucntion-last-title{
        width: 90%;
    }
    .index-page .better-way .claim-your-glasees .my-glass-btn{
        width: 70%;
    }
    .index-page .blue-light-phenomena .try-myself .blue-title{
        font-size: 20px;
    }
    .index-page .dream-elements .insdie-dream-element .company-list .company-glass h2{
        font-size: 35px;
    }
    .index-page .dream-elements .insdie-dream-element .company-list .glass-btn,
    .index-page .dream-elements .insdie-dream-element .claim-btn-discription .btn,
    .index-page .thousands .need-to-feel .my-glass-btn{
        width:70%;
    }
    .index-page .thousands .inside-thousand h4{
        font-size: 127px;
    }
    .index-page .thousands .you-need h2{
        font-size: 154px;
    }
    .index-page .thousands .need-to-feel h3{
        font-size: 134px;
    }
    .index-page .my-glass-btn{
        width: 70%;
    }
}

.get-glass-result{
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 40px;
    .top-titles{
        text-align: center;
        padding-bottom: 30px;
    }
    .row{
        div{
            &:last-child{
                .inside-get-result{
                    &::after{
                        content: unset;
                    }
                }
            }
        }
    }
    .inside-get-result{
        &::after{
            content: url(../images/get-result-arrow.jpg);
            position: absolute;
            top: 100px;
            right: -40px;
        }
        &:last-child{
            content: unset;
        }
        .img{
            padding-bottom: 28px;
            text-align: center;
            img{
                display: inline;
            }
        }
        h2{
            margin: 0;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 800;
            font-size: 25px;
            color: #000000;
            padding-bottom: 28px;
            padding-right:25px;
            padding-left: 25px;
        }
        p{
            margin: 0;
            padding-right: 20px;
            padding-left: 25px;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            font-size: 20px;
            color: #333333;
        }
    }
    .get-result-grid-tab{
        .row{
            padding-bottom: 30px;
        }
    }
}

/*=== Tab ===*/
@media(min-width:768px) and (max-width:959px){
    .get-glass-result{
        &.padding-all{
            padding-bottom: 30px;
            padding-top: 30px;
        }
        .get-result-grid-tab{
            .row{
                padding-bottom: 25px;
                &.tab-row-middel{
                    padding-bottom: 34px;
                }
            }
        }
        .top-titles {
            width: 73%;
            margin: 0 auto;
            font-weight: 900;
        }
        .inside-get-result p{
            padding-right: 0;
            line-height: 24px;
        }
        .inside-get-result h2{
            padding-bottom: 20px;
            line-height: 26px;
        }
        .get-result-grid{
            .row.col-sm-6{
                padding-right: 0;
            }
        }
    }
}

.index-page .better-way .claim-your-glasees .first-thought h3{
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    font-style: italic;
}

@media(min-width:320px) and (max-width:767px){
    .index-page .better-way .claim-your-glasees .first-thought h3{
        margin-top: 0px;
    }
    .get-glass-result{
        .get-result-grid{
            .row{
                .col-xs-12{
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        .top-titles{
            margin: 0;
            font-weight: 900;
            font-size: 25px;
            line-height: 25px;
            padding-bottom: 25px;
        }
        .inside-get-result{
            margin-bottom: 27px;
            &::after{
                content:unset;
            }
            .img{
                padding-bottom: 18px;
                img{
                    width: 60%;
                }
            }
            h2{
                padding: 0 0 13px 0;
                text-align: center;
                font-size: 17px;
                width: 70%;
                margin: 0 auto;
            }
            p{
                padding: 0;
                text-align: center;
                font-size: 17px;
            }
        }
    }
}
