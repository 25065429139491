.modal-warning {
    font: {
        family: 'Abel', Helvetica, Arial, sans-serif;
        
    }
    .modal-content {
        @include border-radius(0);
        .modal-body {
            padding: 0;
        }
    }
    /* modal content */
    .warning-header {
        padding: 4.68vw 7.8vw 2.65vw;
        font: {
            family: 'Abel', Helvetica, Arial, sans-serif;
        }
        h2 {
            margin-bottom: 1.5vw;
            $map: (319px: 47px, 640px: 88px);
            @include poly-fluid-sizing('font-size', $map);
            text-transform: uppercase;
            line-height: .8;
            letter-spacing: -.2vw;
            strong {
                @include inline-flex;
                padding: .2vw 2.9vw 0;
                color: $body-bg;
                background: {
                    image: url('../images/imp-bg.png');
                    repeat: no-repeat;
                    size: cover;
                }
            }
        }
        p {
            font: {
                weight: 700;
            }
            $map: (319px: 12px, 640px: 24px);
            @include poly-fluid-sizing('font-size', $map);
            color: #1b1b1b;
            text-transform: uppercase;
            letter-spacing: -.15vw;
            line-height: $headings-line-height;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    /* Warning header */
    .warning__text {
        padding: 4.5vw 6.5vw;
        p {
            $map: (319px: 12px, 640px: 20px);
            @include poly-fluid-sizing('font-size', $map);
            color: $body-bg;
            &:last-child {
                margin-bottom: 0;
            }
        }
        background: {
                    image: url("../images/popup-p-bg.png");
                    repeat: repeat-x;
                    size: contain;
                }
    }
    /* Warning text */
    .warning__last {
        padding: 4.375vw 3.125vw 7vw;
        ul {
            counter-reset: section;
            li {
                position: relative;
                padding-left: 10vw;
                $map: (319px: 12px, 640px: 20px);
                @include poly-fluid-sizing('font-size', $map);
                color: $gray-base;
                &::before {
                    position: absolute;
                    @include flexbox;
                    @include align-items(center);
                    @include justify-content(center);
                    @include border-radius(100%);
                    width: 7.81vw;
                    height: 7.81vw;
                    top: .1vw;
                    left: 0;
                    counter-increment: section;
                    content: counter(section);
                    font: {
                        weight: 700;
                    }
                    $map: (319px: 13px, 640px: 25px);
                    @include poly-fluid-sizing('font-size', $map);
                    color: $body-bg;
                     background: {
                    image: url("../images/number-bg.png");
                    repeat: no-repeat;
                    size: cover;
                }
                }
                &:not(:last-child) {
                    margin-bottom: 4.5vw;
                }
            }
            /* Li */
        }
        /* ul */
    }
    /* Warning last */
    .warning-checkbox {
        margin: 5vw 3.75vw;
        padding: 3.9vw 3.9vw 3.1vw;
        $map: (319px: 12px, 640px: 21px);
        @include poly-fluid-sizing('font-size', $map);
        color: $body-bg;
        background: {
            color: #242424;
        }
        label {
            margin: 0;
            font: {
                weight: 400;
            }
        }
    }
    /* Checkbox */
    .warning-button {
        padding: 0 6.5vw;
        .btn-continue {
            position: relative;
            border-top: .5vw solid #a2ce4e;
            @include border-radius(2.5vw);
            padding: 5vw;
            font: {
                family: 'Abel', Helvetica, Arial, sans-serif;
            }
            $map: (319px: 15px, 640px: 30px);
            @include poly-fluid-sizing('font-size', $map);
            text-transform: uppercase;
            color: $body-bg;
            line-height: 1;
            letter-spacing: .2vw;
            @include gradient-directional($start-color: #5b9d12, $end-color: #7ab523, $deg: 0deg);
            &::before,
            &::after {
                position: absolute;
                content: '';
                top: 50%;
                @include translate(0, -50%);
            }
            &::before {
                width: 4.53vw;
                height: 5.78vw;
                left: 5.4vw;
                background: {
                    image: url('../images/icon-lock.png');
                    repeat: no-repeat;
                    size: 100%;
                    position: center center;
                }
                opacity: .65;
            }
            /* Before */
            &::after {
                width: 4.53vw;
                height: 4.53vw;
                right: 6.5vw;
                background: {
                    image: url('../images/icon-play-white.svg');
                    repeat: no-repeat;
                    size: 100%;
                    position: center center;
                }
            }
        }
        /* Btn Continue */
    }
    /* Warning button */
}


/* ==========================================================================
   Tablet Css
   ========================================================================== */

@media screen and (min-width: $screen-sm) {
    .modal-warning {
        .modal-dialog {
            width: 733px;
        }
        .modal-content {
            border: none;
        }
        .warning-header {
            padding: $padding-large-vertical*2+8 $padding-large-horizontal*2 $padding-large-vertical*2;
            h2 {
                margin-bottom: $padding-large-vertical;
                letter-spacing: -4.25px;
                strong {
                    padding: $padding-small-vertical $padding-small-horizontal*2;
                    font: {
                        size: 106px;
                    }
                }
            }
            /* h2 */
            p {
                font: {
                    size: $font-size-h3;
                }
                letter-spacing: 3.9px;
            }
        }
        /* Warning header */
        .warning__text {
            padding: $padding-large-vertical*2 $padding-small-horizontal*3;
            p {
                font: {
                    size: $font-size-h4 - 2;
                }
            }
        }
        /* Warning text */
        .warning__last {
            padding: $padding-large-vertical*3+5 $padding-small-horizontal*7+5;
            ul {
                max-width: 605px;
                margin: 0 auto $padding-large-vertical;
                li {
                    padding-top: $padding-small-vertical;
                    padding-left: $padding-small-horizontal*6+5;
                    font: {
                        size: $font-size-h4 - 2;
                    }
                    &::before {
                        width: 33px;
                        height: 33px;
                        top: 6px;
                        font-size: 37px;
                        font-family: "Teko", Helvetica, Arial, sans-serif;
                        padding-top: 7px;
                    }
                    &:not(:last-child) {
                        margin-bottom: $padding-large-vertical*2;
                    }
                }
            }
            /* ul */
            .warning-checkbox {
                max-width: 352px;
                margin: $padding-large-vertical*2+5 auto;
                padding: $padding-large-vertical+8 $padding-small-horizontal*2 $padding-large-vertical+2;
                label {
                    font: {
                        size: $font-size-h4;
                    }
                }
            }
            /* checkbox */
            .warning-button {
                padding: 0;
                .btn-continue {
                    max-width: 308px;
                    border-top-width: 2px;
                    border-bottom: 2px solid #5c9d12;
                    @include border-radius(10px);
                    margin: 0 auto;
                    padding: $padding-large-vertical*2 $padding-large-horizontal*2 $padding-large-vertical*2;
                    font: {
                        size: $font-size-h3;
                    }
                    letter-spacing: .25px;
                    &::before {
                        width: 17px;
                        height: 22px;
                        left: 23px;
                    }
                    &::after {
                        width: 18px;
                        height: 18px;
                        right: $padding-small-horizontal*2+4;
                    }
                }
                .btn-success {
                    background-size: 100%;
                    background-image: url(../images/btn-bg.png);
                    background-repeat: repeat-x;
                    font-weight: normal;
                    max-width: 503px;
                    background-position: left top;
                    background-color: transparent;
                    margin: 0 auto;
                    height: 70px;
                    line-height: 46px;
                    font-family: "Teko", Helvetica, Arial, sans-serif;
                    font-size: 38px;
                    &:hover{
                        background-image: url("../images/btn-roll-over.png");
                   }
                   &:active{
                        background-image: url("../images/btn-roll-over.png");
                        background-color:transparent;
                        box-shadow: none;
                   }
                }

            }

            /* Worning button */
        }
    }
}


/* ==========================================================================
   Tablet Css
   ========================================================================== */

@media screen and (min-width: $screen-md) {
    .modal-warning {
        .modal-dialog {
            width: 819px;
        }
    }
}