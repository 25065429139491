.footer {
    clear: both;
}

.footer-logo {
    padding: 4.68vw 0 6.25vw;
}

.footer-bottom-block {
    padding: 6.8vw 0;
    background-color: #fff;

    ul {
        line-height: 20px;
        margin-bottom: 6vw;
    }

    li {
        padding: 0 9px;
        border-right: 1px solid #656565;
        line-height: 14px;

        a {
            $map: (319px: 15px, 640px: 31px);

            @include poly-fluid-sizing("font-size", $map);

            color: #555555;
            text-decoration: none;

            @include transition(all 350ms ease);

            &:focus {
                color: $blue;
            }
        }

        &:last-child {
            border: none;
        }
    }

    p {
        margin-bottom: 7vw;
        padding: 0 5vw;

        $map: (319px: 15px, 640px: 31px);

        @include poly-fluid-sizing("font-size", $map);

        color: #555555;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.safe-purchase {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $zindex-dropdown;
}

/* ==========================================================================
   Tablet Css
   ========================================================================== */

@media screen and (min-width: $screen-sm) {
    .footer-logo {
        padding: $padding-small-vertical 0 ($padding-large-vertical + 6);
    }

    .footer-bottom-block {
        padding: 0px 0 5px 0;

        ul {
            margin-bottom: 0px;
        }

        li {
            margin-bottom: 0;
            padding: 0 ($padding-xs-horizontal + 2);

            a {
                font: {
                    size: $font-size-base;
                };

                &:focus,
                &:hover {
                    color: $blue;
                }
            }
        }

        p {
            margin-bottom: 10px;
            font: {
                size: $font-size-base;
            };
        }

        p.bor-sty {
            border: 1px solid #b4b4b4;
            max-width: 470px;
            width: 100%;
            display: block;
            margin: 0 auto;
            padding: 10px 0;
        }
    }
}

/* ==========================================================================
   Large Tablet Css
   ========================================================================== */

@media screen and (min-width: $screen-md) {
    .footer-logo {
        padding: ($padding-large-vertical * 2 + 3) 0 ($padding-large-vertical - 3);
    }

    .footer-bottom-block {
        li {
            padding: 0 ($padding-xs-horizontal + 4);

            &::last-child {
                border: none;
            }

            a {
                &:hover {
                    color: $blue;
                }

                &:focus {
                    outline: none;
                    color: $body-bg;
                }
            }
        }
    }
}
