/*=== Popup ===*/
.latest-buyer {
    
    .item {
        border: 1px solid #c5c5c5;
        position: fixed;
        left: $padding-small-vertical;

        @include transition(all 350ms ease);

        &.up {
            bottom: $padding-small-vertical;
        }

        &.down {
            bottom: -180px;
        }

        z-index: 9999;

        .close-tog {
            position: absolute;
            right: 4px;
            cursor: pointer;
            top: 4px;
        }
    }

    .item-buyer {
        @include flexbox;
        @include align-items(center);

        width: 375px;
        border-top: 4px solid #3b76b5;
        padding: 16px 12px 0px 12px;
        background: {
            color: rgba(255, 255, 255, 0.9);
        };
    }

    /* item buyer */
    figure {
        // margin-left: -$padding-small-horizontal * 2 - 5;
        img{
            width:110px;
            height: auto;
            object-fit: contain;
        }
    }

    /* figure */
    .item-caption {
        // margin-left: -$padding-small-horizontal;
        margin-left: 15px;
        width: 100%;
    }

    /* item caption */
    h5 {
        margin-bottom: 4px;
        font-weight: normal;
        color: #000000;
        line-height: 30px;
        font-size: 20px;
        font-family: 'Nunito Sans', sans-serif;
        text-transform:uppercase;
        font-weight: 800;
        margin: 0;
        padding-bottom: 4px;
    }

    /* h5 */
    p {
        margin-bottom: 0;
        letter-spacing: 0.6px;
        font-size: 12px;
        color: #333333; 
        font-family: 'Nunito Sans', sans-serif;
        line-height: 23px;

        &:first-child {}

        &:last-child {
            letter-spacing: -0.2px;
            font-size: 12px;
            font-style: italic;
            color: #333333;
            line-height: 15px;
            font-family: 'Nunito Sans', sans-serif;
            padding-bottom: 10px;
        }
    }

    /* p */
}


#cart-screen{
    background-color: #11233d;

.header{
    .free-txt{
        text-transform: uppercase;
        margin-left: 8px;
        margin-right: 8px;
    }
    .main-head{
        position: relative;
    }
    .inside-head{
        .top-head{
            text-align: center;
            background-image: url(../images/cart/head-bg.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding: 22px 12px 31px 12px;
            img{
                display: inline;
            }
            .top-titles{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 900;
                font-size: 42px;
                color: #ffffff;
                letter-spacing: -0.25px;
                line-height: 42px;
                margin-bottom: 20px;
                margin: 0;
                padding-top: 11px;
            }
        }
        .status-line{
            height:51px;
            font-size: 25px;
            line-height: 27px;
            letter-spacing: -0.25px;
            color: #ffffff;
            background-color: #2b71bb;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 800;
            padding: 7px 12px 8px 56px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            #timer{
                background: #ffff00;
                font-size: 36px;
                color: #000000;
                font-family: 'Oswald', sans-serif;
                line-height: 38px;
                letter-spacing: -0.25px;
                padding: 2px 12px;
                font-weight: 400;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }
    .glass-img{
        position: absolute;
        right:18px;
        top: 1px;
    }
}

#footer{
    padding-bottom: 40px;
    padding-top:35px;
    .copy-right{
        padding-bottom: 7px;
    }
    .foot-menu{
        // padding-bottom: 20px;
        cursor: pointer;
        .copy-right{
            font-size: 16px;
            color: #92a9cb;
            line-height: 30px;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            text-align: center;
        }
        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                font-size: 16px;
                color: #ffffff;
                line-height: 30px;
                a{
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 30px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 400;
                }
                &:last-child{
                    &:after{
                        content: '';
                        padding: 0;
                    }    
                }
                &:after{
                    content: '|';
                    font-size: 16px;
                    color: #ffffff;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
}

.cart-info{
    background-color: #ffffff;
    .inside-cart-info{
        margin: 0 60px 0px 60px;
        padding-bottom: 60px;
        .people-section{
            display: flex;
            align-content: center;
            justify-content: center;
            border-bottom: 1px solid #d8d8d8;
            padding: 11px 5px;
            .view-offer{
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                display: flex;
                align-items: center;
                img{
                    float: left;
                }
                span{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    color: #333333;
                    font-size: 20px;
                    line-height: 27px;
                    text-transform: uppercase;
                }
            }
            @keyframes blink {
                0% {
                       background-color: rgba(203,42,42,1)
                }
                50% {
                       background-color: rgba(203,42,42,0.5)
                }
                100% {
                       background-color: rgba(203,42,42,1)
                }
            }
            @-webkit-keyframes blink {
                0% {
                       background-color: rgba(203,42,42,1)
                }
                50% {
                       background-color: rgba(203,42,42,0.5)
                }
                100% {
                       background-color: rgba(203,42,42,1)
                }
            }
            .blink-part{
                border: 1px solid #939393;
                width: 33%;
                height: 16px;
                .blink-slide{
                    position: relative;
                    top: -5px;
                    padding: 0px 6px 0px 6px;
                    text-align:center;
                    font-size: 11px;
                    border-radius: 0;
                    -moz-transition:all 0.5s ease-in-out;
                    -webkit-transition:all 0.5s ease-in-out;
                    -o-transition:all 0.5s ease-in-out;
                    -ms-transition:all 0.5s ease-in-out;
                    transition:all 0.5s ease-in-out;
                    -moz-animation:blink normal 1.5s infinite ease-in-out;
                    /* Firefox */
                    -webkit-animation:blink normal 1.5s infinite ease-in-out;
                    /* Webkit */
                    -ms-animation:blink normal 1.5s infinite ease-in-out;
                    /* IE */
                    animation:blink normal 1.5s infinite ease-in-out;
                    /* Opera */
                }
            }
            .low-title{
                font-size: 20px;
                color: #cb2a2a;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 900;
                text-transform: uppercase;
            }
            .low{
                padding-left: 34px;
                display: flex;
                display: -webkit-inline-box;
                align-items: center;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
            }
        }
        .congrts{
            padding: 30px 10px 40px 10px;
            p{
                margin: 0;
                font-size: 20px;
                font-family: 'Nunito Sans', sans-serif;
                color: #333333;
                line-height: 30px;
                text-align: center;
                span{
                    color: #2b71bb;
                    font-weight: 800;
                }
                strong{
                    font-weight: 800;
                    color: #333333;
                    text-transform: uppercase;
                }
            }
        }
        .package-box{
            .blocks{
                // border: 4px solid #0c9449;
                &.active-package{
                    border: 4px solid #0c9449;
                    box-shadow: 0px 0 13px -3px #000000;
                    background-color: #ffffff;
                    .save-price{
                        background-color: #0c9449;
                        // transform: rotate(-1deg);
                        &:after{
                            border-top: 46px solid #0c9449;
                        }
                        &:before{
                            border-bottom: 46px solid #0c9449;
                        }
                    }
                    .selected{
                        display: block;
                    }
                    .select-btn{
                        display: none;
                    }
                }
            }
            .second{
                .blocks{
                    .popular-icon{
                        img{
                            position: absolute;
                            left: -8px;
                            top: -21px;
                            width: 119px;
                        }
                    }
                    .title{
                        text-align: right;
                        padding-right: 33px;
                    }
                    .package-img img{
                        // width: fit-content;
                        // padding: 38px 22px 22px 22px;
                        padding: 12px 22px;
                        width: 100%;
                    }
                }
            }
            .first, .third{
                .blocks{
                    .list-items{
                        padding-bottom: 65px;
                    }
                }
            }
            .blocks{
                border: 1px solid #b7bdc4;
                padding-bottom: 27px;
                margin: 0;
                background-color: #f8f9fb;
                cursor: pointer;
                .title{
                    font-size: 25px;
                    color: #ffffff;
                    background-color: #2b71bb;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 800;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 15px 10px;
                }
                .package-img{
                    text-align: center;
                    padding: 15px 5px 15px 5px;
                    height: 250px;
                    // display: flex;
                    display: grid;
                    align-items: center;
                    img{
                        padding: 40px 15px 0px 0;
                        margin: 0 auto;
                        width: fit-content;
                    }
                }
                .save-price{
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 25px;
                    color: #ffffff;
                    font-weight: 800;
                    text-align: center;
                    font-style: italic;
                    background-color: #2b71bb;
                    // transform: rotate(-1deg);
                    width: fit-content;
                    text-transform: capitalize;
                    margin: 17px auto;
                    position: relative;
                    padding: 5px;
                    @media(min-width:1366px){
                        padding: 5px 10px;
                    }
                    line-height: 36px;
                    &:after{
                        content: '';
                        padding-right: 8px;
                        border-right: 8px solid transparent;
                        border-top: 46px solid #2b71bb;
                        position: absolute;
                        top: 0px;
                        right: -15px;
                        @media(min-width:1366px){
                            right: -8px;
                            padding-right: 2px;
                            border-top: 46px solid #2b71bb;
                        }
                    }
                    &:before{
                        content: '';
                        padding-right: 8px;
                        border-left: 8px solid transparent;
                        border-bottom: 46px solid #2b71bb;
                        position: absolute;
                        top: 0px;
                        left: -15px;
                        @media(min-width:1366px){
                            left: -8px;
                            padding-right: 2px;
                            border-bottom: 46px solid #2b71bb;
                        }
                    }
                }
                .list-items{
                    padding: 15px 0px 0 0px;
                    width: 80%;
                    margin: 0 auto;
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0 auto;
                        li{
                            font-size: 16px;
                            font-family: 'Nunito Sans', sans-serif;
                            color: #333333;
                            line-height: 30px;       
                        }
                    }
                }
                .free-shipping{
                    font-family: 'Nunito Sans', sans-serif;
                    color: #333333;
                    font-weight: 800;
                    font-style: italic;
                    text-align: center;
                    padding: 20px 5px;
                    line-height: 27px;
                    font-size: 17px;
                    letter-spacing: 2px;   
                    img{
                        margin-right: 10px;
                    }
                }
                .selected{
                    display: none;
                }
                .select-package{
                    .money-discription{
                        padding-bottom: 30px;
                        padding-top: 30px;
                    }
                    .select-btn{
                        cursor: pointer;
                        font-family: 'Nunito Sans', sans-serif;
                        color: #fff;
                        font-weight: 800;
                        background-color: #2b71bb;
                        padding: 12px 18px;
                        border-radius: 50px;
                        width: 85%;
                        @media(min-width:1366px){
                            width: 250px;
                        }
                        margin: 0 auto;
                        text-transform: capitalize;
                        font-size: 25px;
                        text-align: center;
                    }
                    .selected{
                        background-color: #0c9449;
                        cursor: pointer;
                        font-family: 'Nunito Sans', sans-serif;
                        color: #fff;
                        font-weight: 800;
                        padding: 12px 18px;
                        border-radius: 50px;
                        width: 85%;
                        @media(min-width:1366px){
                            width: 250px;
                        }
                        margin: 0 auto;
                        text-transform: capitalize;
                        font-size: 25px;
                        text-align: center;
                    }
                }
                .price{
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 40px;
                    color: #000000;
                    font-weight: 800;
                    text-align: center;
                    padding: 15px 5px 3px 5px;
                    margin: 0 20px;
                    p{
                        text-align: center;
                        margin: 0;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                        font-style: italic;
                        font-size: 17px;
                        color: #000000;
                        line-height: 15px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }
                    .first-price{
                        font-family: 'Nunito Sans', sans-serif;
                        font-size: 25px;
                        color: #000000;
                        font-weight: 300;
                        position: relative;
                        top: -14px;
                        font-weight: 800;
                    }
                    .last-price{
                        font-weight: 800;
                        font-family: 'Nunito Sans', sans-serif;
                        font-size: 25px;
                        color: #000000;
                        position: relative;
                        top: -14px;
                    }
                }
                .bag-service-section{
                    border-bottom: 1px solid #dbdbdb;
                    margin: 0 20px;
                    .pd-0{
                        padding: 0;
                    }
                    .row{
                        padding: 0;
                        .bag-service{
                            font-family: 'Tenor Sans', sans-serif;
                            font-size: 14px;
                            color: #3d3d3d;
                            line-height:20px;
                            letter-spacing: -0.2px;
                            text-align: left;
                            margin: 0;
                            span{
                                font-size: 12px;
                                text-transform: uppercase;
                                display: inline-block;
                                width: 100%;
                            }
                        }
                        .free-shipping{
                            font-family: 'Tenor Sans', sans-serif;
                            font-size: 13px;
                            color: #0d93bd;
                            line-height: 46px;
                            line-height: 32px;
                            letter-spacing: -0.2px;
                            text-align: center;
                            padding:6px 5px 5px 5px;
                        }
                    }
                }
                .package-rating{
                    text-align: center;
                    padding: 10px 0;
                    ul{
                        padding: 0;
                        list-style: none;
                        display: inline-block;
                        margin: 0;
                        li{
                            float: left;
                            padding-right: 8px;
                        }
                    }
                    P{
                        margin: 0;
                        font-size: 13px;
                        font-family: 'Muli', sans-serif;
                        font-style: italic;
                        color: #616161;
                    }
                }
                .buy-now-btn{
                    text-align: center;
                    padding-bottom: 14px;
                    img{
                        width: 90%;
                        margin: 0 auto;
                        cursor: pointer;
                        &:hover{
                            background-image: url(../images/buy-now-hover.png);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain; 
                        }
                    }
                }
                .subscribe{
                    font-family: 'Muli', sans-serif;
                    font-weight: bold;
                    color: #454545;
                    font-size: 12px;
                    line-height: 18px;
                    text-transform: uppercase;
                    padding: 0 22px 10px 22px;
    
                    input[type=checkbox] {
                        display: none;
                    }
                    input[type=checkbox]:checked + label {
                    margin: 0;
                    position: relative;
                    background: url(../images/subscribe.png) no-repeat;
                    height: 20px;
                    width: 20px;
                    transition: box-shadow 0.4s, border 0.4s;
                    cursor: pointer;
                    top:4px;
                    }
    
                    input[type=checkbox] + label {
                        margin: 0;
                        position: relative;
                        background: url(../images/select-subscribe.png) no-repeat;
                        height: 20px;
                        width: 20px;
                        transition: box-shadow 0.4s, border 0.4s;
                        cursor: pointer;
                        top:7px;
                    }
                    span{
                        font-style: italic;
                        color: #e23a79;
                    }
                }
                .content-area{
                    font-family: 'Muli', sans-serif;
                    color: #3d3d3d;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.2px;
                    padding: 0 22px;
                }
            }
        }
        .pack-data{
            padding-top: 50px;
        }
    }
}

}



#cvv-modal .modal-body{
    padding: 3%;
}
#biling__detailstab .form-group label, #biling__detailstabTab .form-group label{
    font-weight: normal;
}


.money-back-package-manage{
    .unsubscribepackage{
        display: none;
    }
    &.uncheckbox3{
        .unsubscribepackage{
            display: block;
        }
        .subscribepackage{
            display: none;
        }
    }
    &.uncheckbox2{
        .unsubscribepackage{
            display: block;
        }
        .subscribepackage{
            display: none;
        }
    }
    &.uncheckbox1{
        .unsubscribepackage{
            display: block;
        }
        .subscribepackage{
            display: none;
        }
    }
}



#cart-screen{

    #free-shipping-tag{
        background: #2b2729;
        padding: 14px 0;
        text-align: center;
        .free-shipping-tag-text{
            font-family: 'Muli', sans-serif;
            font-size:14px;
            font-weight: bold;
            color: #ffffff;
            letter-spacing: 1.5px;
            line-height: 23px;
            text-transform: uppercase;
            span{
                color: #ff91ba;
            }
            .available-text{
                font-weight: normal;
                letter-spacing: 0;
                text-transform: capitalize;
                color: #ffffff;
            }
            #timer{
                background: #1292bc;
                font-size: 18px;
                color: #fff;
                font-family: 'Muli', sans-serif;
                line-height: 23px;
                letter-spacing: 0.8px;
                padding: 2px 5px;
            }
        }
    }

    #cart-info{
        .left-side-part{
            .select-package{
                .package-title{
                    padding-left: 20px;
                    padding-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .your-package{
                        font-family: 'Tenor Sans', sans-serif;
                        font-size: 22px;
                        color: #333333;
                        letter-spacing: -0.2px;
                    }
                    .current-avail{
                        font-family: 'Muli', sans-serif;
                        font-size: 12px;
                        color: #333333;
                        line-height: 20px;
                        letter-spacing: 0.8px;
                    }
                    .progress-custom{
                        padding-left: 5px;
                        padding-top: 10px;
                        width: 31%;
                        .low-stock{
                            font-size: 12px;
                            font-family: 'Muli', sans-serif;
                            font-weight: bold;
                            color: #cb2a2a;
                            padding-left: 10px;
                            position: relative;
                            top: -3px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }
                    }
                    .progress{
                        height: 16px;
                        margin-bottom: 0;
                        background-color: #fff;
                        border-radius: 0;
                        border: 1px solid #999999;
                        padding: 1px;
                        width: 50%;
                        float: left;
                        
                        .progress-bar{
                            background: #cb2a2a;
                        }
                    }
                }
                .slick-dots{
                    padding: 0;
                    list-style: none;
                    display: flex !important;
                    justify-content: flex-start;
                    li{
                        button{
                            border: none;
                            background: transparent;
                            font-size: 23px;
                            font-family: 'Yellowtail', cursive;
                            color: #15959f;
                            &:focus{
                                outline: 0;
                            }
                        }
                        &.slick-active{
                            background-color: #15959f;
                            button{
                                color: #ffffff;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        margin-right: 8px;
                        width: 25px;
                        height: 25px;
                        text-align: center;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .real-result{
                    h3{
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 300;
                        font-size: 26px;
                        color: #333333;
                        text-transform: uppercase;
                        margin: 0;
                        padding-bottom: 30px;
                        padding-top: 30px;
                        border-top: 1px solid #d8d8d8;
                    }
                    .cart-slider{
                        .slick-slide{
                            &:focus{
                                outline: 0;
                            }
                        }
                        .shipping-adv{
                            padding-bottom: 25px;
                        }
                        .ship-title{
                            // display: flex;
                            padding-bottom: 20px;
                            img{
                                margin-left: 23px;
                            }
                            h2{
                                font-size: 16px;
                                color: #333333;
                                font-family: 'Yellowtail', cursive;
                                line-height: 20px;
                                letter-spacing: 2px;
                                margin: 0;
                                display: flex;
                                align-items: center;
                            }
                        }
                        .ship-text{
                            p{
                                font-family: 'Nunito Sans', sans-serif;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 0;
                                line-height: 25px;
                            }
                        }
                    }
                }
                .money-discription{
                    padding-bottom: 30px;
                    padding-top: 30px;
                    p{
                        margin: 0;
                        font-size: 16px;
                        color: #333333;
                        line-height: 24px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 300;
                    }
                }
            }
            .money-back{
                padding-top: 5px;
                .money-back-head{
                    border-top: 1px solid #bebebe;
                    border-bottom: 1px solid #bebebe;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    .title{
                        text-transform: uppercase;
                    }
                    .value{
                        &.pack-value{
                            font-size: 22px;
                            text-transform: uppercase;
                            color: #000000;
                            line-height: 36px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: bold;
                        }
                    }
                    .title, .value{
                        font-size: 16px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                        color: #333333;
                        line-height: 31px;
                    }
                    .text-left{
                        .value{
                            text-transform: uppercase;
                        }
                    }
                }
                .money-back-price-value{
                    padding: 0;
                    .row{
                        padding-top: 4px;
                        padding-bottom:4px;
                        border-bottom: 1px solid #bebebe;
                        margin: 0;
                        &:last-child{
                            padding-bottom: 0;
                            border-bottom:none;
                        }
                        .text-left{
                            padding-right: 0;
                        }
                        .value{
                            span{
                                color: #0c9449;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                            &.free{
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                        .title, .value{
                            font-size: 16px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 400;
                            color: #333333;
                            line-height: 31px; 
                        }
                    }
                }
                .total{
                    background: #F2F2F2;
                    padding: 7px 0;
                    .inside-total{
                        display: flex;
                        align-items: center;
                        .title{
                            font-size: 17px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 600;
                            color: #000000;
                            line-height: 26px;
                            letter-spacing: 2px; 
                            text-transform: uppercase;
                            font-style: italic;
                        }
                        .value{
                            font-size: 22px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: bold;
                            color: #000000;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .right-side-part{
            border: 1px solid #dcdcdc;
            border-radius: 2px;
            .shipping-info-box{
                text-align: center;
                background: #F2F2F2;
                padding:30px 0;
                border-bottom: 1px solid #dcdcdc;
                h2{
                    font-size: 26px;
                    color: #000000;
                    margin: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 300;
                    text-transform: uppercase;
                }   
                p{
                    img{
                        position: relative;
                        top: -2px;
                    }
                    padding-top: 10px;
                    font-size: 13px;
                    color: #515151;
                    line-height: 20px;
                    letter-spacing: 1px;
                    margin: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight:400;
                    text-transform: uppercase;
                }
            }
            .secure-box{
               padding: 36px 30px 28px 30px;
               .pl-15{
                   padding: 0 24px;
               }
               .pl-25{
                    padding: 0 24px;
                }
               .visa-image{
                   text-align: center;
                   padding-bottom: 30px;
                   img{
                       display: inline;
                   }
               }
               .billing-address{
                   padding-bottom: 20px;
                   text-align: center;
                   font-size: 16px;
                   font-family: 'Nunito Sans', sans-serif;
                   font-weight: 400;
                   color: #646464;
                   line-height: 23px;
               }
               #biling__details{
                    .form-group{
                        label{
                            padding: 0 0 0 0;
                            color: #333333;
                            font-size: 16px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 400;
                            display: block;
                            letter-spacing: .2px;       
                        }
                    }
               }
               #biling__detailstab .form-group, #biling__detailstabTab .form-group{
                    label{
                        font-size: 16px;
                        font-family: 'Nunito Sans', sans-serif;
                        color: #646464;
                    }
                    .form-control{
                        font-size: 16px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight:400; 
                    }

               } 
               .card__detail{
                   .form-group{
                    margin-left: 0;
                    margin-right: 0;
                       .form-control{
                           border-radius: 2px;
                           margin-bottom: 18px;
                           font-family: 'Nunito Sans', sans-serif;
                           font-weight:400; 
                       }
                       &.card-name{
                            margin-bottom: 0;
                        }
                        label{
                            font-size: 16px;
                            font-family: 'Nunito Sans', sans-serif;
                            color: #646464;
                            padding-bottom: 9px;
                            margin: 0;
                            font-weight: 400;
                            width: 100%;
                            display: inline-block;
                        }
                   }
                   .form-group-box{
                       display: inline-block;
                       width: 100%;
                       .pay-now{
                           img:hover{
                            background-image: url(../images/cart/pay-btn-active.png);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                           }
                       }
                       .secure-logo{
                           text-align: center;
                           padding-top: 12px;
                           img{
                               padding-right: 10px;
                               &:last-child{
                                   padding-right: 0;
                               }
                           }
                       }
                       .info-guid{
                           position: absolute;
                           bottom: 28px;
                           right: -17px;
                       }
                       .form-group{
                           margin-left: 0;
                           margin-right: 0;
                       }
                        .form-group.form-group_date{
                            width: 65%;
                            float: left;
                            .form-control{
                                background-color: #fff;
                                background-image: url(../images/cart/down-arrow.png);
                                background-position: right 10px center;
                                background-repeat: no-repeat;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                            
                                width: 48%;
                                float: left;
                                margin-right: 4%;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                        .form-group.form-group_cvv{
                            width: 30%;
                            float: right;
                            position: relative;
                        }
                    }
               }
            }
        }
    }

    #countdown{
        background: #da4079;
        text-align: center;
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        .coundown-text{
            font-size: 15px;
            color: #ffffff;
            font-family: 'Muli', sans-serif;
            line-height: 20px;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                padding-right: 10px;
            }
            span{
                letter-spacing: 0.2px;
                text-transform: capitalize;
            }
        }
    }
}

/*=== Responsive View ===*/

@media(min-width:1025px) and (max-width:1199px){}

/*--- Tab View ---*/

@media(min-width:767px) and (max-width:959px){
    .container {
        width: 750px;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    #cart-screen #footer .foot-menu ul li{
        &:nth-child(3){
            &:after{
                content: none;
            }
        }
    }
    #cart-screen .header .inside-head{
        .status-line{
            display: inline-block;
            padding: 0;
            font-size: 20px;
            margin: 0 auto;
            text-align: center;
            width: 100%;
        }
        #timer{
            line-height: 38px;
            position: relative;
            top:7px;
            font-size: 26px !important;
        }
    } 
    .pay-now{
        img{
            margin: 0 auto;
        }
    }
    #cart-screen .cart-info{
        .inside-cart-info{
            margin: 0 20px 0px 20px;
            .package-box{
                width: 95%;
                margin:0 auto;
                .blocks{
                    .list-items ul li{
                        line-height: 25px;
                        display: flex;
                        align-items: flex-start;
                        img{
                            padding-right:10px;
                            position: relative;
                            top: 5px;
                        }
                    }
                    .title{
                        font-size: 25px;
                        padding-right: 10px !important;
                    }
                    .price p {
                        font-size: 15px;
                    }
                }
                .money-discription{
                    padding-bottom: 30px;
                    padding-top: 30px;
                }
                .first, .third{
                    padding-top: 50px !important;
                }
                .second{
                    .blocks{
                        .popular-icon{
                            img{
                                left: 66px;
                                top: 50px;
                                width: 110px;
                            }  
                        } 
                        .package-img{
                            margin-top: 45px;
                            padding-top: 50px;
                            img{
                                padding: 22px 8px;
                            }
                        }
                    } 
                }
                .row{
                    .col-sm-4{
                        padding: 0;
                    }
                }
            } 
        } 
    } 
    #cart-screen #cart-info .left-side-part{
        .select-package{
            .real-result{
                .cart-slider{
                    .shipping-adv{
                        padding-bottom: 60px;
                    }
                } 
            } 
            .slick-dots{
                justify-content: center;
            }
        } 
        .money-back{
            .tab-money-back{
                width: 85%;
                margin: 0 auto;
            }
            .money-back-head{
                .value.pack-value{
                    font-size: 16px;
                }
            } 
            .title{
                font-size: 13px;
            }
        } 
    } 
    #cart-screen{
        .header{
            .free-txt{
                margin-left: 0;
                margin-right: 0;
            }
            .inside-head{
                .status-line{
                    padding: 3px 0;
                    #timer{
                        margin-left: 0;
                        margin-right: 0;
                        padding: 0px 12px;
                        top: 4px;
                        font-size: 23px !important;
                    }
                }
            } 
        } 
    } 
}

@media(min-width:960px) and (max-width:1024px){
    .pay-now{
        text-align: center;
        img{
            display: inline;
        }
    }
    #cart-screen .header .inside-head{
        .status-line{
            display: inline-block;
            padding: 0;
            font-size: 20px;
            margin: 0 auto;
            text-align: center;
            width: 100%;
        }
        #timer{
            line-height: 38px;
            position: relative;
            top:7px;
            font-size: 26px !important;
        }
    } 
    #cart-screen .cart-info .inside-cart-info{
        .congrts p{
            font-size: 18px;
        }
        .package-box{
            .blocks{
                .title{
                    font-size: 18px;
                }
                .price p {
                    font-size: 15px;
                }
            }
            .money-discription{
                padding-bottom: 30px;
                padding-top: 30px;
            }
            .second{
                .blocks{
                    .popular-icon{
                        img{
                            left: -5px;
                            top: -12px;
                            width: 89px;
                        }  
                    } 
                } 
            }
        } 
    } 
    #cart-screen #cart-info .left-side-part{
        .money-back{
            .money-back-head{
                .value.pack-value{
                    font-size: 16px;
                }
            } 
            .title{
                font-size: 13px;
            }
        } 
    } 
}

@media(min-width:768px) and (max-width:1024px){
    #cart-screen #free-shipping-tag{
        padding: 11px 0;
    }
    .tab-sidebar{
        padding-top: 20px;
    }
    #cart-screen{
        #cart-info{
            .left-side-part{
                .select-package{
                } 
            }
            .right-side-testinomial{
                width: 100%;
                h2{
                    letter-spacing: 2.5px;
                    font-size: 17px;
                }
            }
            .right-side-part{
                width: 100%;
                margin: 0;
                .shipping-info-box{
                    h2{
                        font-size: 18px;
                    }
                } 
            } 
            .tab-right-part{
                display: flex;
                align-items: center;
                padding-top: 35%;
            }
        }
    }
}

@media(width:1024px){
   #shipping-screen{
        .right-side{
            .form-area{
                .form{
                    fieldset{
                        input{
                            width:330px !important;
                        }
                        select{
                            width: 330px !important;
                        }
                    }   
                } 
            } 
        }
    }
}

/*--- Mobile View ---*/

@media(min-width:320px) and (max-width:459px){

}

@media(min-width:460px) and (max-width:767px){
    
}

@media(min-width:568px) and (max-width:767px){
}

@media(max-width:420px){
    #cart-screen #footer  .foot-menu{
        padding-bottom: 0 !important;
        ul{
            display: inline-block !important;
            text-align: center;
            padding-bottom: 15px;
            li{
                float: left;
                display: contents;
                &:nth-child(3){
                    a{
                        position: relative;
                        top: -8px;
                    }
                    &:after{
                        position: relative;
                        top: -6px;
                    }
                }
                &:nth-child(4){
                    a{
                        position: relative;
                        top: -8px;
                    }
                }
                &:nth-child(2){
                    &:after{
                        content: '';
                        padding: 0;
                    }
                }
                &:last-child{
                    &:after{
                        padding: 0;
                    }
                }
                a{
                    font-size: 14px;
                }
                a:after{
                    padding: 0 10px;
                }
            }
        }
        .copy-right{
            line-height: 23px;
            font-size: 14px;
            padding: 0 25px;
        }
    }
}

@media(max-width:767px){
    #cart-screen .header .free-txt{
        margin-left: 0;
        margin-right: 0;
    }
    #cart-screen #cart-info{
        padding-bottom: 0 !important;
    }
    #cart-screen #footer{
        padding-bottom: 46px;
        padding-top: 16px;
        .copy-right{
            font-size: 13px;
        }
        .partners{
            ul.top{
                li{
                    width: 50%;
                }
            } 
        }
    }
    .container{
        padding: 0;
    }
    #cart-screen .header .inside-head{
        .top-head{
            padding: 10px 12px 10px 12px;
            .top-titles{
                font-size: 25px;
                padding-top: 3px;
            }
        }
        .status-line{
            display: inline-block;
            width: 100%;
            font-size: 15px;
            height: auto;
            padding: 8px 5px 5px 5px;
            #timer{
                font-size: 21px;
                line-height: 25px;
                font-weight: bold;
                padding-top: 0;
                padding-bottom: 0;
                margin-left: 2px;
            }
        }
    }

    .mobile-real-result{
        h3{
            text-align: center;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 300;
            font-size: 26px;
            color: #333333;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: 30px;
            padding-top: 30px;
            border-top: 1px solid #d8d8d8;
        }
        .cart-slider{
            padding: 0 20px;
            .slick-slide{
                &:focus{
                    outline: 0;
                }
            }
            .shipping-adv{
                padding-bottom: 25px;
            }
            .ship-title{
                padding-bottom: 20px;
                img{
                    margin-left: 23px;
                }
                h2{
                    font-size: 16px;
                    color: #333333;
                    font-family: 'Yellowtail', cursive;
                    line-height: 20px;
                    letter-spacing: 2px;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ship-text{
                text-align: center;
                p{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333333;
                    margin: 0;
                    line-height: 25px;
                }
            }
        }
        .slick-dots{
            padding: 0;
            list-style: none;
            display: flex !important;
            justify-content: center;
            li{
                button{
                    border: none;
                    background: transparent;
                    font-size: 23px;
                    font-family: 'Yellowtail', cursive;
                    color: #15959f;
                    &:focus{
                        outline: 0;
                    }
                }
                &.slick-active{
                    background-color: #15959f;
                    button{
                        color: #ffffff;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                margin-right: 8px;
                width: 25px;
                height: 25px;
                text-align: center;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }


    #cart-screen .cart-info{
        .inside-cart-info{  
            margin: 0;
            .people-section.mobile-people-section{
                margin: 0 15px 0px 15px;
                .pr-0{
                    padding-right: 0;
                }
                .low-title{
                    font-size: 15px;
                }
                .blink-part{
                    width: 50%;
                }
                .low{
                    width: 100%;
                    padding-left: 0;
                }
                p.current{
                    margin: 0;
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 15px;
                    color: #333333;
                }
                .view-offer{
                    display: inline-block;
                    width: 100%;
                    font-size: 14px;
                    line-height: 10px;
                    span{
                        font-size: 15px;
                    }
                    img{
                        position: relative;
                        top: 6px;
                    }
                }
            } 
            .congrts{
                padding: 15px 10px 25px 10px;
                margin: 0 15px 0px 15px;
                p{
                    font-size: 15px;
                    line-height: 23px;
                    span{
                        display: inherit;
                        text-align: center;
                    }
                    strong{
                        display: inherit;
                    }
                }
            }
            .package-box{
                margin: 0 15px 0px 15px;
                .row{
                    .col-xs-12{
                        padding: 0;
                    }
                }
                .first, .third{
                    .blocks{
                        .list-items{
                            padding-bottom: 0;
                            padding-top: 10px;
                            text-align: center;
                        }
                    }    
                }
                .second{
                    .blocks{
                        .title{
                            text-align: right;
                            padding-right: 44px;
                        }
                        .package-img img{
                            padding: 22px;
                        }
                        .popular-icon img{
                            left: -11px;
                        }
                    }
                }
                .blocks{
                    border: 3px solid #b7bdc4;
                    margin-bottom: 30px;
                    &.active-package{
                        .save-price{
                            &:before{
                                border-bottom: 40px solid #0c9449;
                            }
                            &:after{
                                border-top: 40px solid #0c9449;
                            }
                        }
                    } 
                    .title{
                        font-size: 17px;
                        padding: 13px 10px;
                    }
                    .package-img{
                        height: auto;
                        padding-bottom: 0;
                        img{
                            padding: 0;
                        }
                    }
                    .price{
                        padding-top: 0;
                    }
                    .save-price{
                        font-size: 17px;
                        padding: 2px;
                        &:before{
                            border-bottom: 40px solid #2b71bb;
                        }
                        &:after{
                            border-top: 40px solid #2b71bb;
                        }
                    }
                    .list-items{
                        padding-bottom: 0;
                        width: 90%;
                    }
                    .free-shipping{
                        font-size: 15px;
                    }
                    .select-package{
                        .select-btn{
                            font-size: 18px;
                            text-transform: uppercase;
                            width: 90%;
                        }
                        .selected{
                            font-size: 18px;
                            text-transform: uppercase;
                            width: 90%; 
                        }
                    } 
                }
            }
            .pack-data{
                padding-top: 0;
                .row{
                    margin: 0;
                    .col-xs-12{
                        padding: 0;
                    }
                }
            }
        }   
    } 

    #cart-screen{
        #cart-info{
            .left-side-part{
                .money-discription{
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 0 !important;
                    p{
                        font-size: 15px;
                        text-align: center;
                    }
                }
                .money-back{
                    .row{
                        margin: 0;
                    }
                    .money-back-img{
                        margin-top: 30px;
                        img{
                            width: 50%;
                        }
                    }
                    .total{
                        .inside-total{
                            .title{
                                font-size: 15px;
                            }
                        } 
                    } 
                    .money-back-head{
                        padding: 10px 0;
                        .value.pack-value{
                            font-size: 15px;
                        }
                    }
                    .money-back-price-value{
                        .row{
                            .text-left{
                                padding-left: 0;
                            }
                            .value{
                                font-size: 15px;
                            }
                        }
                    }
                } 
            } 
        } 
    } 

    .header .logo{
        text-align: center;
        img{display: inline;}
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail{
        width: 100%;
        margin: 0 auto;
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .form-group.card-name{
        margin: 0;
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .form-group-box .form-group.form-group_date{
        margin: 0;
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .form-group-box .pay-now{
        width: 108%;
        left: -8px;
        position: relative;
        img{
            width: 100%;
        }
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .form-group-box .info-guid{
        right: 5px;
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .form-group-box .form-group.form-group_cvv{
        margin: 0;
    }
    #cart-screen #cart-info .left-side-part .money-back .money-back-head .text-right{
        padding-right: 0;
    }
    #cart-screen #cart-info .left-side-part .money-back .money-back-head .title{
        font-size: 13px;
    }
    #cart-screen #cart-info .left-side-part .money-back .money-back-head .value{
        font-size:14px;
    }
    #cart-screen #cart-info .left-side-part .money-back .money-back-price-value .row .title, #cart-screen #cart-info .left-side-part .money-back .money-back-price-value .row .value{
        font-size: 14px;
    }
    #cart-screen #cart-info .right-side-part .secure-box .card__detail .pay-now img{
        margin: 0 auto;
    }
    #cart-screen{
        .header{
            // padding:10px 0;
            .logo{
                text-align: center;
            }
        }
        #cart-info{
            padding-bottom: 20px;
            .tab-sidebar{
                padding: 0;
            }
            .left-side-part{
                .select-package{
                    .package-title{
                        display: inline;
                        padding: 0;
                        .current-avail{
                            float: left;
                        }
                        .progress{
                            width: 28%;
                            margin-top: 5px;
                            margin-left: 5px;
                        }
                    }
                    .package-left{
                        text-align: center;
                    }
                    .progress-custom{
                        text-align: center;
                        width: 100% !important;
                        .low-stock{
                            top: 2px !important;
                            font-size: 9px !important;
                        }
                    }
                } 
                .mobile-view-tab{
                    .free-shipping{
                        font-family: 'Tenor Sans', sans-serif;
                        font-size: 13px; //19
                        color: #0d93bd;
                        line-height: 29px;//46
                        letter-spacing: -0.2px;
                        text-align: left;
                        padding: 0px;
                    }
                    .first{
                        border-right: 1px solid #2a2a2a;
                    }
                    .package-info-in{
                        padding-bottom: 15px;
                        border-top: 1px solid #3d3d3d;
                        padding-top: 20px;
                    }
                    .subscribe{
                        border-top: 1px solid #3d3d3d;
                        margin-top: 10px;
                        p{
                            margin-top: 3px !important;
                            padding-bottom: 25px;
                        }
                    }
                    .price-shipping{
                        .percent-off{
                            font-family: 'Muli', sans-serif;
                            font-size: 28px;
                            color: #e23a79;
                            line-height: 28px;
                            letter-spacing: -0.4px;
                            font-style: italic;
                        }
                        .price{
                            font-size: 18px;
                            color: #3d3d3d;
                            font-family: 'Tenor Sans', sans-serif;
                            letter-spacing: -0.2px;
                        }
                    }
                    .nav{
                        li{
                            width: 100% !important;
                            margin-bottom: 15px !important;
                            box-shadow: 1px 3px 6px #e9e9e9;
                        }
                    }
                }
                .money-back{
                    .money-back-img{
                        margin-bottom: 20px;
                    }
                    .total{
                        .row{
                            margin: 0;
                        }
                    } 
                    padding: 0px;
                    .money-back-img{
                        text-align: center;
                        img{
                            display: inline;
                        }
                    }
                    .content{
                        padding-bottom: 20px;
                        p{
                            text-align: center;
                            font-size: 17px;
                            font-family: 'Muli', sans-serif;
                            color: #717171;
                            line-height: 18px;
                            letter-spacing: 0.2px;
                            font-weight: normal;
                            line-height: 28px;
                            letter-spacing: -0.2px;
                        }
                    }
                }
            }
            .right-side-part{
                width: 100%;
                margin-left: 0;
                border-bottom: 1px solid #dcdcdc;
                border-left: 0;
                border-right: 0;
                border-radius: 0;
                margin-top:0px;
                .shipping-info-box{
                    padding: 20px 10px;
                    h2{
                        font-size: 23px;
                    }
                }
                .secure-box{
                    padding: 17px 25px 28px 15px;
                    .card__detail{
                        .form-group-box{
                            .form-group.form-group_date{
                                margin-bottom: 3px;
                            }
                            .form-group.form-group_cvv{
                                margin-bottom: 3px;
                            }
                            .secure-logo{
                                img{
                                    width: 32%;
                                    object-fit: contain;
                                }
                            } 
                        } 
                    } 
                }
            }
            .right-side-testinomial{
                width: 90%;
                h2{
                    line-height:25px;
                    letter-spacing: 2px;
                    font-size: 19px;
                }
            }
        } 
        #free-shipping-tag{
            .free-shipping-tag-text{
                font-size: 12px;
                line-height: 21px;
                letter-spacing: 0.8px;
                text-align: left;
                #timer{
                    font-size: 13px;
                }
            }
        } 
        
        #footer{
            .foot-menu{
                padding-bottom: 25px;
            }
            .partners{
                margin: 0;
                ul{
                    display: inline-block;
                    margin-bottom: 10px;
                    &.top{
                        li {
                            width: 50%;
                        }
                    } 
                }
            }
        } 
    }
}

@media(max-width:319px){
    #cart-screen{
        #free-shipping-tag{
            .free-shipping-tag-text{
                font-size: 11px;
            }
        }
        #cart-info{
            .left-side-part{
                .mobile-view-tab{
                    .free-shipping{
                        font-size: 11px;
                    }
                } 
                .select-package{
                    .package-tab{
                        .nav{
                            li{
                                a{
                                    font-size: 11px !important;
                                }
                            } 
                        } 
                        .tabing-section{
                            .tab-content{
                                .subscribe{
                                    font-size: 11px !important;
                                }
                            } 
                        } 
                    } 
                    .package-title{
                        .progress {
                            width: 22%;
                        }
                    } 
                } 
            } 
            .right-side-part{
                .shipping-info-box{
                    h2{
                        font-size: 17px;
                    }
                } 
            } 
            .right-side-testinomial{
                h2{
                    font-size: 19px;
                }
            } 
        } 
    }
    #footer{
        .foot-menu{
            ul{
                li{
                    font-size: 12px;
                }
            } 
        } 
    } 
}



#cart-screen #track-progress .steps .thankyou{
    background: #ff91ba;
}





@media(min-width:1280px){
    #cart-screen{
        .package-box{
            .first{
                width: 31.83333%;
                padding: 0 22px;
            }
            .second{
                width: 36.33333%;
            }
            .third{
                width: 31.83333%;
                padding: 0 22px;
            }
        }
        #free-shipping-tag{
            padding: 5px 0;
            .free-shipping-tag-text{
                letter-spacing: 1.7px;
                padding-left: 45px;
                #timer{
                    padding: 2px 12px;
                    margin-left: 10px;
                }
            }
        }
        #cart-info{
            padding-bottom: 21px;
            .left-side-part{
                .money-back{
                    .money-discription{
                        padding-top: 20px;
                    }
                    .total{
                        .inside-total{
                            .title{
                                font-size: 17px;
                                line-height: 21px;
                            }
                        } 
                    }
                }
                .select-package{
                    .real-result{
                        h3{
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 300;
                            font-size: 26px;
                            color: #333333;
                            text-transform: uppercase;
                            margin: 0;
                            padding-bottom: 30px;
                            padding-top: 30px;
                            border-top: 1px solid #d8d8d8;
                        }
                        .cart-slider{
                            .slick-slide{
                                &:focus{
                                    outline: 0;
                                }
                            }
                            .shipping-adv{
                                padding-bottom: 25px;
                            }
                            .ship-title{
                                // display: flex;
                                padding-bottom: 20px;
                                img{
                                    margin-left: 23px;
                                }
                                h2{
                                    font-size: 16px;
                                    color: #333333;
                                    font-family: 'Yellowtail', cursive;
                                    line-height: 20px;
                                    letter-spacing: 2px;
                                    margin: 0;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                            .ship-text{
                                p{
                                    font-family: 'Nunito Sans', sans-serif;
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: #333333;
                                    margin: 0;
                                    line-height: 25px;
                                }
                            }
                        }
                    }
                    .money-discription{
                        padding-bottom: 30px;
                        padding-top: 30px;
                        p{
                            margin: 0;
                            font-size: 16px;
                            color: #333333;
                            line-height: 24px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 300;
                        }
                    }
                }
            } 
            .right-side-part{
                width: auto;
                margin-left: 8%;
                margin-top: 16px;
                .shipping-info-box{
                    padding: 27px 0;
                }
                .secure-box{
                    .billing-address{
                        font-size: 16px;
                    }
                    .card__detail{
                        .form-group-box{
                            .pay-now{
                                display: inline-block;
                                // margin-top: -15px;
                                cursor: pointer;
                                margin-top: 8px;
                            }
                            .secure-logo{
                                padding-top: 20px;
                            }
                            .form-group.form-group_date{
                                .form-control{
                                    width: 45%;
                                }
                            } 
                        }
                    } 
                }
            }
        } 
        #footer{
            
        }
    } 
}


@media(max-width:350px){
    #cart-screen{
        .header{
            .logo{
                img{
                    width: 130px;
                }
            }
        }
        #track-progress{
            .steps{
                ul{
                    li{
                        span{
                            width: 30px;
                            height: 32px;
                        }
                        img{
                            width: 30px;
                        }
                        p{
                            font-size: 10px;
                        }
                    }   
                } 
                .progress-line {
                    width: 62%;
                }
            } 
        } 
    }
}
