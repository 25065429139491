/* Single side border-radius*/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
          border-radius: $radius;
}
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
          border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
           border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
          border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
             border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
          border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
           border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
          border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
             border-top-left-radius: $radius;
}
