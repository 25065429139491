// @import url(https://fonts.googleapis.com/css?family=Abel);
// @import url(https://fonts.googleapis.com/css?family=Teko:300,400,500,600,700);
// @import url(https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700);
// @import url(https://fonts.googleapis.com/css?family=Tenor+Sans);
// @import url(https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i);
// @import url(https://fonts.googleapis.com/css?family=Allura);


@font-face {
	font-family: 'Audrey-Normal';
	src:  url('../../fonts/Audrey-Normal/Audrey-Normal.otf')  format('opentype'),
			url('../../fonts/Audrey-Normal/Audrey-Normal.eot') format('embedded-opentype'),
			url('../../fonts/Audrey-Normal/Audrey-Normal.woff') format('woff'), 
			url('../../fonts/Audrey-Normal/Audrey-Normal.ttf')  format('truetype'), 
			url('../../fonts/Audrey-Normal/Audrey-Normal.svg#Audrey-Normal') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'TheConstellationofHeracles';
	src:  url('../../fonts/TheConstellationofHeracles/TheConstellationofHeracles.eot') format('embedded-opentype'),
			url('../../fonts/TheConstellationofHeracles/TheConstellationofHeracles.woff') format('woff'), 
			url('../../fonts/TheConstellationofHeracles/TheConstellationofHeracles.ttf')  format('truetype'), 
			url('../../fonts/TheConstellationofHeracles/TheConstellationofHeracles.svg#Audrey-Normal') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Regular';
	src:  url('../../fonts/ProximaNova-Regular/ProximaNova-Regular.otf')  format('opentype'),
			url('../../fonts/ProximaNova-Regular/ProximaNova-Regular.eot') format('embedded-opentype'),
			url('../../fonts/ProximaNova-Regular/ProximaNova-Regular.woff') format('woff'), 
			url('../../fonts/ProximaNova-Regular/ProximaNova-Regular.ttf')  format('truetype'), 
			url('../../fonts/ProximaNova-Regular/ProximaNova-Regular.svg#Audrey-Normal') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova-Semibold';
	src:  url('../../fonts/ProximaNova-Semibold/ProximaNova-Semibold.otf')  format('opentype'),
			url('../../fonts/ProximaNova-Semibold/ProximaNova-Semibold.eot') format('embedded-opentype'),
			url('../../fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff') format('woff'), 
			url('../../fonts/ProximaNova-Semibold/ProximaNova-Semibold.ttf')  format('truetype'), 
			url('../../fonts/ProximaNova-Semibold/ProximaNova-Semibold.svg#Audrey-Normal') format('svg');
	font-weight: normal;
	font-style: normal;
}
