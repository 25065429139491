#header{
    &.open{
        .header-container{
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
            z-index: 1;
        }
    }
    .header-container{
        background: #e55088;
        padding: 7px 0;
        -webkit-transform: translate(0, -150px);
        transform: translate(0, -150px);
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .row{
        align-items: center;
        display: flex;
        .logo{
        
        }
        .offer-title{
            font-size: 14px;
            color: #ffffff;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            font-family: 'Muli', sans-serif;
            font-weight: bold;
            span{
                font-size: 13px;
                color: #ffffff;
                text-transform: none;
                letter-spacing: normal;
                padding-left: 20px;
                font-family: 'Muli', sans-serif;
                font-weight: normal;
            }
        }
    }
}



