label.invalid{
    width: 100% !important;
    color: red !important;

    @media screen and (min-width: 768px){
        width: 341px !important;
    }

    @media screen and (min-width: 1024px){
        width: 382px !important;
    }
}

input.invalid, select.invalid, textarea.invalid{
    border-color: #fac5c5 !important;
}
input.valid, select.valid, textarea.valid{
    border-color: #a4cb83 !important;
}
