.modal-footer {
    .modal-content {
        @include border-radius(0);
    }
    .modal-header {
        border: none;
        padding: $padding-large-vertical+3 $padding-large-vertical+5;
        text-align: center;
        background-color: $gray-base;
    }
    .modal-title {
        margin: 0;
        font: {
            family: $font-family-europe;
            size: $font-size-h2;
        }
        color: $body-bg;
    }
    .modal-body {
        text-align: left;
        padding: $padding-large-vertical*4 $padding-small-horizontal*3 $padding-large-vertical*3;
        h3 {
            margin-bottom: $padding-large-vertical+5;
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-base;
        }
        /* h3 */
        h4 {
            margin-bottom: 3px;
            font-size: $font-size-base;
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-base;
        }
        /* h4 */
        h5 {
            margin-bottom: 3px;
            font-size: $font-size-base;
            font-weight: 700;
            text-transform: none;
            color: $gray-base;
        }
        /* h5 */
        h6 {
            margin-bottom: 3px;
            font-size: $font-size-base + 3;
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-base;
        }
        /* h6 */
        p {
            margin-bottom: $padding-large-vertical*2+3;
            color: $gray;
            &:last-child {
                margin-bottom: 0;
            }
            strong {
                color: $gray-base;
            }
            a {
                text-decoration: underline;
                color: #00b4ff;
            }
        }
        /* P */
        ul {
            margin-bottom: $padding-large-vertical*2+3;
            color: $gray;
            &:last-child {
                margin-bottom: 0;
            }
            li {
                line-height: 1.65;
            }
        }
        /* ul */
        address {
            line-height: 1.5;
            color: $gray;
            a {
                text-decoration: underline;
                color: #00b4ff;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        /* Address */
        .table {
            >thead {
                text-transform: uppercase;
                color: $gray;
                background-color: #e3e3e3;
            }
            tr {
                color: $gray;
            }
        }
        .table-bordered {
            tbody>,
            tfoot> {
                >tr {
                    >td,
                    >th {
                        border-color: #e3e3e3;
                        vertical-align: middle;
                    }
                }
            }
            thead> {
                >tr {
                    >th {
                        border-color: #cacaca;
                        border-bottom: none;
                        &:last-child {
                            min-width: 165px;
                        }
                        span {
                            text-transform: none;
                        }
                    }
                }
            }
        }
        .modal-heading {
            margin-bottom: $padding-large-vertical*2+5;
            h2 {
                margin-bottom: $padding-small-vertical+3;
                font: {
                    family: $font-family-prompt;
                    size: $font-size-h3 + 2;
                    weight: 700;
                }
                text-transform: uppercase;
                color: $gray-base;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            h3 {
                font: {
                    family: $font-family-prompt;
                    size: $font-size-base + 1;
                    weight: 700;
                }
                text-transform: uppercase;
                color: $gray-base;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            ul {
                margin-bottom: 0;
                li {
                    font: {
                        style: italic;
                    }
                    line-height: 1.6;
                    color: $gray;
                }
            }
        }
        /* modal heading */
        .modal-text {
            text-align: left;
            ul {
                margin-bottom: $padding-large-vertical*2+3;
                padding-left: $padding-small-horizontal;
                color: $gray;
                li {
                    position: relative;
                    margin-bottom: 3px;
                    &::before {
                        position: relative;
                        @include inline-flex;
                        content: '';
                        width: 6px;
                        height: 6px;
                        top: -2px;
                        margin-right: $padding-small-horizontal - 2;
                        background-color: $blue;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            /* ul */
        }
        /* modal text */
        .modal-box {
            border-bottom: 1px solid #e3e3e3;
            padding: $padding-large-vertical*2+5 0;
            &:first-child,
            &:first-of-type {
                border-top: 1px solid #e3e3e3;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        /* modal box */
        .modal-contact {
            padding: $padding-large-vertical*3 0;
            p {
                margin-bottom: $padding-xs-vertical*2;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        /* modal contact */
        .modal-faq {
            border-bottom: 1px solid #e3e3e3;
            padding: $padding-large-vertical*3 0;
            h4 {
                margin-bottom: $padding-small-vertical;
                font: {
                    size: $font-size-base;
                    weight: 700;
                }
                text-transform: uppercase;
                color: $gray-base;
            }
            ul {
                margin-bottom: 0;
                li {
                    line-height: 2;
                    a {
                        text-decoration: underline;
                        color: $gray;
                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
            .faq-box {
                &:nth-child(odd) {
                    padding-right: $padding-small-horizontal*3;
                }
                &:nth-child(even) {
                    padding-left: $padding-small-horizontal*3;
                }
            }
            .divider {
                height: 1px;
                margin: $padding-large-vertical*2+5 0;
                background-color: #e3e3e3;
            }
            &:first-child {
                padding-top: 0;
            }
        }
        /* modal FAQ */
    }
    /* modal body */
    .modal-footer {
        border: none;
        text-align: center;
        color: #bababa;
        background-color: $gray-base;
    }
    /* modal Footer */
}


/* ==========================================================================
   Tablet Css
   ========================================================================== */

@media screen and (min-width: $screen-sm) {
    .modal-footer {
        .modal-dialog {
            width: 95%;
            max-width: 800px;
        }
    }
}