#shipping-screen{
    background-color: #11233d;
.pl-0{
    padding-left: 0;
}
.pr-0{
    padding-right: 0;
}

    .header{
        .main-head{
            position: relative;
        }
        .inside-head{
            .top-head{
                background-image: url(../images/shipping-header-bg.jpg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                padding: 22px 12px 22px 56px;
                .top-titles{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 900;
                    font-size: 42px;
                    color: #ffffff;
                    letter-spacing: -0.25px;
                    line-height: 42px;
                    margin-bottom: 20px;
                    margin: 0;
                    padding-top: 11px;
                }
            }
            .status-line{
                font-size: 20px;
                line-height: 18px;
                color: #ffffff;
                background-color: #2b71bb;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 800;
                padding: 18px 12px 18px 56px;
            }
        }
        .glass-img{
            position: absolute;
            right:18px;
            top: 1px;
        }
    }

    #shipping-info{
        .inside-shipping-info{
            padding: 60px 56px 25px 56px; 
            background-color: #ffffff;
            .row{
                margin: 0;
            }
            .left-side{
                .titles{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 300;
                    font-size: 26px;
                    color: #000000;
                    line-height: normal;
                    padding: 23px 5px;
                    text-transform: uppercase;
                    border-top: 1px solid #d0cccc;
                    border-bottom: 1px solid #d0cccc;
                }
                .testinomials-list{
                    .list{
                        padding: 11px 0;
                        .profile-rating-sec{
                            display: flex;
                            align-content: center;
                            .profile-img{
                                img{
                                    border-radius: 50%;
                                    width: 80px;
                                    object-fit: cover;
                                    height: 80px;
                                }
                            }
                            .user-info{
                                padding-left: 18px;
                                padding-top: 7px;
                            }
                            h2{
                                font-family: 'Yellowtail', cursive;
                                margin: 0;
                                font-size: 16px;
                                color: #000000;
                                line-height: 20px;
                                letter-spacing: 4px;
                                padding-top: 13px;
                                padding-bottom: 4px;
                            }
                            h3{
                                text-transform: uppercase;
                                font-weight: 400;
                                font-style: italic;
                                font-family: 'Nunito Sans', sans-serif;
                                margin: 0;
                                font-size: 10px;
                                line-height: 20px;
                                letter-spacing: 4px;
                            }
                        }
                        .discription{
                            padding-top: 15px;
                            font-family: 'Nunito Sans', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            color: #333333;
                            line-height: 25px;   
                        }
                    }
                }
            }
        } 
        .right-side{
            border: 1px solid #dcdcdc;
            border-radius: 2px;
            .shipping-info-box{
                text-align: center;
                background: #f3f3f3;
                padding:30px 0;
                border-bottom: 1px solid #dcdcdc;
                h2{
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 300;
                    font-size: 26px;
                    color: #000000;
                    line-height: normal;
                    text-transform: uppercase;
                    padding: 0 0 10px 0;
                    margin:0;
                }   
                p{
                    font-size: 13px;
                    color: #515151;
                    line-height: 20px;
                    letter-spacing: 1px;
                    margin: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform:uppercase;
                    img{
                        margin-right: 10px;
                    }
                    
                }
            }
            .form-area{
                padding: 24px 0;
                .form{
                    padding: 0 0px 0px 0;
                    width: 100%;
                    display: block;
                    text-align: center;
                    fieldset{   
                        padding: 0 0 15px 0;
                        select{
                            color:#999999 !important;
                            font-size: 15px;
                        }
                        input{
                            color: #333333;
                            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                color:#999999;
                                font-size: 15px;
                            }
                            &::-moz-placeholder { /* Firefox 19+ */
                                color: #999999;
                                font-size: 15px;
                            }
                            &:-ms-input-placeholder { /* IE 10+ */
                                color: #999999;
                                font-size: 15px;
                            }
                            &:-moz-placeholder { /* Firefox 18- */
                                color: #999999;
                                font-size: 15px;
                            }
                        }
                        &.phone, &.email{
                            input{
                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color:#999999;
                                    font-style: italic;
                                    font-size: 15px;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                    color: #999999;
                                    font-style: italic;
                                    font-size: 15px;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                    color: #999999;
                                    font-style: italic;
                                    font-size: 15px;
                                }
                                &:-moz-placeholder { /* Firefox 18- */
                                    color: #999999;
                                    font-style: italic;
                                    font-size: 15px;
                                }
                            }
                        }
                        label{
                            width: 95px;
                            color: #333333;
                            text-transform: none;
                            font-weight:normal;
                            font-size: 16px;
                            font-family: 'Nunito Sans', sans-serif;
                            letter-spacing: 0px;
                            text-align: right;
                            margin-right: 10px;
                            line-height: 30px;
                        }
                        input{
                            font-weight: 400;
                            border: 1px solid #b7b7b7;
                            border-radius: 3px;
                            max-width: 270px;
                            background: #fff;
                            color: #333333;
                            height:38px;
                            padding: 0 10px;
                            width: 100%;
                            font-size: 15px;
                            font-family: 'Nunito Sans', sans-serif;
                            &:focus{
                                outline: 0;
                            }
                        }
                        select{
                            font-weight: 400;
                            max-width: 270px;
                            border: 1px solid #b7b7b7;
                            border-radius: 3px;
                            height:38px;
                            color: #9c9c9c;
                            background: #fff;
                            padding: 0 10px;
                            width: 100%;
                            background-color: #fff;
                            background-image: url(../images/logos/down-arrow.jpg);
                            background-position: right 10px center;
                            background-repeat: no-repeat;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            &:focus{
                                outline: 0;
                            }
                        }  
                    } 
                }
            }   
            .continue-btn{
                width: 65%;
                margin: 0 auto;
                position: relative;
                padding-top: 0;
                padding-bottom: 15px;

                button{
                    border: none;
                    background: none;
                }
                img{
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                    cursor: pointer;
                }
            } 
            .info-logos{
                text-align: center;
                padding-top: 10px;
                position: relative;
                padding-left: 37px;
                img{
                    display: inline-block;
                    padding-right: 20px;
                    &:last-child{
                        padding-right: 0;
                    }
                }
            }
        }
        .logos{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 27px;
            img{
                padding-right: 10px;
                &:last-child{
                    padding: 0;
                }
            }
        }
    }

    #footer{
        padding-bottom: 80px;
        padding-top:35px;
        .copy-right{
            padding-bottom: 7px;
        }
        .foot-menu{
            padding-bottom: 20px;
            cursor: pointer;
            .copy-right{
                font-size: 16px;
                color: #92a9cb;
                line-height: 30px;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                text-align: center;
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                text-align: center;
                li{
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 30px;
                    display: inline;
                    a{
                        font-size: 16px;
                        color: #ffffff;
                        line-height: 30px;
                        font-family: 'Nunito Sans', sans-serif;
                        font-weight: 400;
                    }
                    &:last-child{
                        &:after{
                            content: '';
                            padding: 0;
                        }    
                    }
                    &:after{
                        content: '|';
                        font-size: 16px;
                        color: #ffffff;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    #countdown{
        background: #2b71bb;
        text-align: center;
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        .stock-title{
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 900;
            font-size: 20px;
            color: #ffffff;
            line-height: 28px;
        }
        .timer{
            font-family: 'Nunito Sans', sans-serif;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
        }
        .coundown-text{
            font-size: 15px;
            color: #ffffff;
            font-family: 'Muli', sans-serif;
            line-height: 20px;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                padding-right: 10px;
            }
            span#timer{
                padding-left: 10px;
                letter-spacing: 0.2px;
                text-transform: capitalize;
            }
            span{
                letter-spacing: 0.2px;
                text-transform: capitalize;
            }
        }
    }
}

/*=== Responsive View ===*/

@media(min-width:1025px) and (max-width:1199px){}

/*--- Tab View ---*/

@media(min-width:767px) and (max-width:959px){}

@media(min-width:960px) and (max-width:1024px){}

@media(min-width:768px) and (max-width:1024px){
    #shipping-screen{
        #shipping-info{
            .logos{
                display: block;
                justify-content: center;
                text-align: center;
                margin: 0 auto;
                width: 100%;
                img{
                    display: inline-flex;
                }
            }
            .right-side{
                .form-area{
                    .form{
                        fieldset{
                            input{
                                width:230px;
                            }
                            select{
                                width: 230px;
                            }
                        }   
                    } 
                } 
            } 
        }
    }
}

@media(width:1024px){
   #shipping-screen{
        .right-side{
            .form-area{
                .form{
                    fieldset{
                        input{
                            width:330px !important;
                        }
                        select{
                            width: 330px !important;
                        }
                    }   
                } 
            } 
        }
        #shipping-info{
        } 
    }
}

@media(min-width:768px) and (max-width:959px){
    #shipping-screen{
        .header{
            .glass-img{
                img{
                    width: 100%;
                    margin: 0 0 0 auto;
                }
                position: absolute;
                right: 13px;
                top: 10px;
            }
            .inside-head{
                .status-line{
                    padding: 18px 12px 18px 35px;
                    line-height: 22px;
                }
                .top-head{
                    padding: 22px 12px 22px 34px;
                    .top-titles{
                        font-size: 26px;
                        padding-top: 6px;
                    }
                } 
            } 
        } 
        #shipping-info{
            .container{
                padding: 0 !important;
            }
            .logos{
                display: flex;
                img{
                    padding-right: 0;
                }
            } 
            .inside-shipping-info{
                padding: 30px 35px 25px 35px;
                .left-side .titles{
                    text-align: center;
                }
            }
            .right-side{
                margin-left: 20px;
                .continue-btn{
                    width: 87%;
                    left: 0;
                    padding-top: 15px;
                }
                .shipping-info-box{
                    padding: 20px 0;
                    h2{
                        padding: 0 12px 10px 12px;
                    }
                } 
            } 
            .left-side{
                .slick-dots{
                    padding: 0;
                    list-style: none;
                    display: flex !important;
                    justify-content: center;
                    li{
                        button{
                            border: none;
                            background: transparent;
                            font-size: 23px;
                            font-family: 'Yellowtail', cursive;
                            color: #15959f;
                            &:focus{
                                outline: 0;
                            }
                        }
                        &.slick-active{
                            background-color: #15959f;
                            button{
                                color: #ffffff;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        margin-right: 10px;
                        width: 35px;
                        height: 35px;
                        text-align: center;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                .testinomials-list{
                    .list{
                        padding: 31px 0 !important;
                    }
                } 
            }
        }
        #footer{
            .foot-menu{
                padding-bottom: 0;
            }
        } 
    } 
}

.left-side{
    .slick-dots{
        padding: 0;
        list-style: none;
        display: flex !important;
        justify-content: center;
        li{
            button{
                border: none;
                background: transparent;
                font-size: 23px;
                font-family: 'Yellowtail', cursive;
                color: #15959f;
                &:focus{
                    outline: 0;
                }
            }
            &.slick-active{
                background-color: #15959f;
                button{
                    color: #ffffff;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            margin-right: 10px;
            width: 35px;
            height: 35px;
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


@media(max-width:767px){
    #shipping-screen #footer{
        padding-bottom: 56px;
        padding-top: 16px;
        .copy-right{
            font-size: 13px;
        }
        .partners{
            ul.top{
                li{
                    width: 50%;
                }
            } 
        }   
    }
    #shipping-screen{
        .container{
            padding: 0 !important;
        }
        .header{
            .inside-head{
                .status-line{
                    font-size: 17px;
                    padding: 14px 23px 12px 23px;
                    margin-top: -15px;
                    text-align: center;
                }
                .top-head{
                    padding:12px 0 0px 0px;
                    background-image: url(../images/shipping-header-bg-mobile.jpg);
                    background-position:top;
                    img{
                        margin: 0 auto;
                        width:150px;
                    }
                    .glass-img-mobile{
                        img{
                            width: 80%;
                        }
                    }
                    .top-titles{
                        text-align: center;
                        font-size: 25px;
                        padding-top:8px;
                        line-height: 31px;
                    }
                }
            } 
        } 
        #free-shipping-tag{
            .free-shipping-tag-text{
                font-size: 11px;
                letter-spacing: 1px;
                text-align: left;
                line-height: 20px;
            }
        }
        #shipping-info{
            .inside-shipping-info{
                padding: 0;
                .pl-0{
                    padding-left:  0px;
                }
                .pr-0{
                    padding-right: 0px;
                }
                .pull-left{
                    &.pl-0{
                        padding-left:  15px;
                    }   
                }
            }
            .mobile-visible{
                padding: 0;
                border-bottom: 1px solid #cfcfcf;
            }
            .right-side{
                border: none;
                .shipping-info-box{
                    padding:13px 0 11px 0;
                    border-top: 1px solid #dcdcdc;
                    h2{
                        font-size: 23px !important;
                        padding:0px 30px 10px 30px;
                    }
                }
                .form-area{
                    .form{
                        padding: 0;
                        fieldset{
                            text-align: center;
                            width:85%;
                            margin: 0 auto;
                            max-width:82%;
                            input{
                                font-family: 'Nunito Sans', sans-serif;
                                font-size: 16px;
                                padding-top: 10px;
                                font-weight: 400;
                                padding-bottom: 13px;
                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    font-size: 15px;
                                    opacity: 1;
                                    color:#333333;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                    font-size: 15px;
                                    opacity: 1;
                                    color:#333333;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                    font-size: 15px;
                                    opacity: 1;
                                    color:#333333;
                                }
                                &:-moz-placeholder { /* Firefox 18- */
                                    font-size: 15px;
                                    opacity: 1;
                                    color:#333333;
                                }
                            }
                            select{
                                font-size: 15px;
                                padding-top: 10px;
                                padding-bottom: 15px;
                                color:#333333 !important;
                                font-family: 'Nunito Sans', sans-serif;
                                font-weight: 400;
                            }
                            &.phone, &.email{
                                position: relative;
                                input{
                                    &:focus + .placeholder{
                                        display: none;
                                    }
                                    &:active + .placeholder{
                                        display: none;
                                    }
                                    &.not-empty + .placeholder{
                                        display: none;
                                    }
                                    &.empty + .placeholder{
                                        display: block;
                                    }
                                }
                                .placeholder {
                                    position: absolute;
                                    pointer-events: none;
                                    top: 12px;
                                    left: 12px;
                                    color:#333333;
                                    font-family: 'Nunito Sans', sans-serif;
                                    font-weight: 400;
                                    font-size: 15px;
                                    span{
                                        color:#999999;
                                        font-style: normal;
                                        font-size: 15px;
                                        font-style: italic;
                                        text-transform: capitalize;
                                        font-family: 'Nunito Sans', sans-serif;
                                        font-weight: 400;
                                    }
                                  }
                                input{
                                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                        color:#333333;
                                        font-style: normal;
                                        font-size: 16px;
                                        opacity: 1;
                                    }
                                    &::-moz-placeholder { /* Firefox 19+ */
                                        color: #333333;
                                        font-style: normal;
                                        font-size: 16px;
                                        opacity: 1;
                                    }
                                    &:-ms-input-placeholder { /* IE 10+ */
                                        color: #333333;
                                        font-style: normal;
                                        font-size: 16px;
                                        opacity: 1;
                                    }
                                    &:-moz-placeholder { /* Firefox 18- */
                                        color: #333333;
                                        font-style: normal;
                                        font-size: 16px;
                                        opacity: 1;
                                    }
                                }
                            }
                            label{
                                display: none;
                            }
                            input{
                                width:100%;
                                margin: 0 auto;
                                height: 44px;
                                max-width:100%;
                                border-radius: 3px;
                            }
                            select{
                                height: 44px;
                                width: 100%;
                                max-width:100%;
                                margin: 0 auto;
                            }
                        }   
                    } 
                }
                .continue-btn{
                    left: auto;
                    width: 93%;
                }
                .info-logos{
                    left: 0;
                    padding-left: 0;
                }
            } 
            .left-side .titles{
                border: none !important;
                font-size: 22px !important;
                text-align: center;
            }
            .left-side{
                .testinomials-list{
                    padding: 0 10px;
                }
                .slick-dots{
                    padding: 0;
                    list-style: none;
                    display: flex !important;
                    justify-content: center;
                    li{
                        button{
                            border: none;
                            background: transparent;
                            font-size: 23px;
                            font-family: 'Yellowtail', cursive;
                            color: #15959f;
                            &:focus{
                                outline: 0;
                            }
                        }
                        &.slick-active{
                            background-color: #15959f;
                            button{
                                color: #ffffff;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                        margin-right: 10px;
                        width: 35px;
                        height: 35px;
                        text-align: center;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .logos{
                padding: 0;
                display: block;
                justify-content: center;
                text-align: center;
                margin: 0 auto;
                width: 100%;
                img{
                    padding-right: 0px;
                    display: inline-flex;
                    width: 32%;
                    padding-left: 0px;
                    &:nth-child(4){
                        width: 42%;
                    }
                    &:nth-child(5){
                        width: 42%;
                    }
                }
            } 
        }
        .logos{
            margin-top: 20px !important;
            border-top: 1px solid #d0cccc;
            padding: 15px 5px !important;
        }
    }
}

@media(max-width:321px){
    #shipping-screen{
        #free-shipping-tag{
            .free-shipping-tag-text{
                font-size: 10.5px;
            }
        }
        #shipping-info{
            .right-side{
                .shipping-info-box{
                    h2{
                        font-size: 18px;
                    }
                    p{
                        font-size: 12px;
                    }
                } 
            } 
        } 
        #footer{
            .foot-menu{
                ul{
                    display: inline-block !important;
                    text-align: center;
                    padding-bottom: 15px;
                    li{
                        float: left;
                        display: contents;
                        &:nth-child(3){
                            a{
                                position: relative;
                                top: -8px;
                            }
                            &:after{
                                position: relative;
                                top: -6px;
                            }
                        }
                        &:nth-child(4){
                            a{
                                position: relative;
                                top: -8px;
                            }
                        }
                        &:nth-child(2){
                            &:after{
                                content: '';
                                padding: 0;
                            }
                        }
                        &:last-child{
                            &:after{
                                padding: 0;
                            }
                        }
                        a{
                            font-size: 14px;
                        }
                        a:after{
                            padding: 0 10px;
                        }
                    }
                }
                .copy-right{
                    line-height: 23px;
                    font-size: 14px;
                    padding: 0 25px;
                }
            }
        }
    }
}


/*=== Models Css ===*/

#contactModel{
    .modal-header{
        padding: 0;
    }
    #contactModelLabel{
        font-family: 'Tenor Sans', sans-serif;
        font-size: 20px;
        color: #ffffff;
        background: #da4079;
        padding: 25px 10px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
    }
    .close{
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: 1;
        span{
            color: #ffffff;
            font-size: 30px;
        }
    }
    .modal-body{
        padding: 25px 40px;
        .contact-form{
            .content-text{
                p{
                    font-size: 14px;
                    color: #3d3d3d;
                    line-height: 22px;
                    letter-spacing: -0.2px;
                    font-family: 'Muli', sans-serif;
                    padding-bottom:17px;
                    margin: 0;
                }
            }
            .form-area{
                padding-top: 10px;
                width: 80%;
                margin: 0 auto;
                .form-group{
                    padding: 0 0px 0px 0;
                    width: 100%;
                    display: block;
                    text-align: center;
                    .intro-text{
                        font-size: 16px;
                        font-family: 'Muli', sans-serif;
                        color: #3d3d3d;
                    }
                        input{
                            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                color:#808080;
                                font-style: italic;
                            }
                            &::-moz-placeholder { /* Firefox 19+ */
                                color: #808080;
                                font-style: italic;
                            }
                            &:-ms-input-placeholder { /* IE 10+ */
                                color: #808080;
                                font-style: italic;
                            }
                            &:-moz-placeholder { /* Firefox 18- */
                                color: #808080;
                                font-style: italic;
                            }
                        }
                        label{
                            color: #646464;
                            text-transform: none;
                            font-weight:normal;
                            font-size: 13px;
                            font-family: 'Muli', sans-serif;
                            letter-spacing: 0px;
                            text-align: right;
                            line-height: 30px;
                            span{
                                color: #da4079;
                            }
                        }
                        input{
                            border: 1px solid #b7b7b7;
                            border-radius: 12px;
                            background: #fff;
                            color: #9c9c9c;
                            height:38px;
                            padding: 0 10px;
                            width: 100%;
                            font-size: 12px;
                            &:focus{
                                outline: 0;
                            }
                        }
                        textarea{
                            border: 1px solid #b7b7b7;
                            border-radius: 12px;
                            background: #fff;
                            color: #9c9c9c;
                            resize: none;
                            padding: 0 10px;
                            width: 100%;
                            font-size: 12px;
                            &:focus{
                                outline: 0;
                            }
                        }
                        select{
                            border: 1px solid #b7b7b7;
                            border-radius: 12px;
                            height:38px;
                            color: #9c9c9c;
                            background: #fff;
                            padding: 0 10px;
                            width: 100%;
                            background-color: #fff;
                            background-image: url(../images/select-bg.png);
                            background-position: right center;
                            background-repeat: no-repeat;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            &:focus{
                                outline: 0;
                            }
                        }  
                    } 
            }

            .submit-forms{
                text-align: center;
                font-size: 14px;
                font-family: 'Muli', sans-serif;
                color: #3d3d3d;
                padding-top: 10px;
                span{
                    color: #da4079;
                }
            }
        }
        
    }
}


#footer{
    .foot-menu{
        ul{
            cursor: pointer;
        }
    }
}
#privacyModel{
    .modal-header{
        padding: 0;
    }
    #privacyModelLabel{
        font-family: 'Tenor Sans', sans-serif;
        font-size: 20px;
        color: #ffffff;
        background: #da4079;
        padding: 25px 10px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
    }
    .close{
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: 1;
        span{
            color: #ffffff;
            font-size: 30px;
        }
    }
    .modal-body{
        padding: 25px 40px;
        .rules{
            padding-bottom: 33px;
            h2{
                font-family: 'Muli', sans-serif;
                font-weight: 500;
                color: #3d3d3d;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.2px;
                padding-bottom: 10px;
                margin: 0;
            }
            p{
                font-family: 'Muli', sans-serif;
                color: #3d3d3d;
                font-size: 14px;
                margin: 0;
            }
        }
    }
}

@media(max-width:767px){
    #shipping-screen{
        .header{
        }
        #free-shipping-tag{
            padding: 10px 0;
        }
        #track-progress{
            .steps{
                ul{
                    li.third3{
                        span{
                            top: -6px;
                        }
                    } 
                    li.second2{
                        span{
                            top: -6px;
                        }
                    }
                } 
            } 
        } 
    }
    #contactModel{
        .modal-body{
            padding: 25px 20px;
            .contact-form{
                .form-area{
                    width: 100%;
                    .form-group{
                        text-align: left;
                        margin: 0;
                    }  
                } 
                .intro-text{
                    margin-top: 10px;
                }
            } 
        }
    } 
}


@media(max-width:1220px){
    #shipping-screen{
        #shipping-info{
            .container{
                padding: 0 15px;
                .col-lg-offset-1{
                    margin-left: auto;
                }
            }
        } 
    } 
}

@media(min-width:768px){
    #shipping-screen{
        #shipping-info{
            .right-side{
                .form-area{
                    .form{
                        fieldset{
                            input{
                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: transparent;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                     color: transparent;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                     color: transparent;
                                }
                                &   :-moz-placeholder { /* Firefox 18- */
                                     color: transparent;
                                }
                            }
                        } 
                    } 
                } 
            } 
        } 
    }
}


//1240 Container Width
@media(min-width:1240px){
    .container{
        // width: 1240px;
        padding: 0;
    }
    .homePage{
        #forteen-days{
            .banner-section{
                .banner-information{
                    .special-offer{
                        p{
                            margin-top: -15px;
                        }
                    } 
                } 
            } 
        }
        #beautiful{
            .col-lg-offset-2{
                margin-left: 11.66667%;
            }
            .beautiful-content{
                .beautiful-discription{
                    padding: 7px 0 32px 0;
                }
            } 
        }
        #benefites{
            .col-lg-offset-2{
                margin-left: 11.66667%;
            }
            .special-offer{
                p{
                    margin-top: 5px;
                }
            } 
        }
        #super-food{
            .food-discription{
                .discription-text{
                    width: 80%;
                    margin: 0 auto;
                }
            }
            .super-food-slider{
                padding: 30px 0 10px 0;
                .slick-dots li button{
                    position: relative;
                    top: 3px;
                }
                .benefit-box{
                    width: 100%;
                    .food-content{
                        width: 82.5%;
                    }
                }
            }
            .special-offer{
                p{
                    margin-top: 5px;
                }
            }
        }

        #weight-loss{
            .weight-loss-grid{
                .masonry-grid{
                    padding: 0 80px;
                }
            } 
        } 
        #new-way{
            .inside-new-way{
                padding-top: 75px;
            }
        } 
        #package{
            .package-box{
                .blocks{
                    .bag-service-section{
                        .pd-0{
                            padding: 12px;
                        }
                        .row{
                            .bag-service{
                                span{
                                    text-align: left;
                                }
                            } 
                        } 
                    } 
                } 
            } 
        }
        #faq{
            .panel-group{
                width: 76%;
                margin: 0 auto;
            }
        }
    } 
}

@media(min-width:1280px){
    #shipping-screen{
        #footer{
            padding-bottom: 80px;
            padding-top:35px;
            .partners{
                ul{
                    li{
                        padding-right: 28px;
                    }
                } 
            } 
        }
        #free-shipping-tag{
            padding: 5px 0;
        }
        #track-progress{
            .steps{
                .progress-line{
                    width: 55%;
                }
                ul{
                    li{
                        width: 27%;
                        padding-left: 20px;
                        span{
                            margin: 0 auto 0 auto;
                        }
                        &.third3 span{
                            top: -15px;
                        }
                    }
                } 
            } 
        } 
        #shipping-info{
            .container{
                .right-side{
                    .continue-btn{
                        width: 65%;
                        top: 3px;
                    }
                    .form-area{
                        padding: 40px 0;
                        .form{
                            fieldset{
                                padding:0 0 18px 0;
                                position: relative;
                                left: -19px;
                            }
                        } 
                    } 
                    .shipping-info-box{
                        padding: 21px 0;
                    } 
                    .continue-btn{
                        img{
                            width: auto;
                        }
                    }                 }
                .left-side{
                    padding-right:50px;
                }
            }
        } 
    }
}
